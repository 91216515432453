// src/index.ts
var BENEVOLE_CODE = "benevole";
var HARD_CODE = "hard";
var SOFT_CODE = "soft";
var VIEUX_CODE = "vieux";
var ORGA_CODE = "orga";
export {
  BENEVOLE_CODE,
  HARD_CODE,
  ORGA_CODE,
  SOFT_CODE,
  VIEUX_CODE
};
