export default {
  default: () => import("/overbookd/apps/web/layouts/default.vue"),
  header: () => import("/overbookd/apps/web/layouts/header/Header.vue"),
  "header-profile": () => import("/overbookd/apps/web/layouts/header/HeaderProfile.vue"),
  "header-logo": () => import("/overbookd/apps/web/layouts/header/Logo.vue"),
  "navigation-desktop-side-nav": () => import("/overbookd/apps/web/layouts/navigation/DesktopSideNav.vue"),
  "navigation-mobile-bottom-nav": () => import("/overbookd/apps/web/layouts/navigation/MobileBottomNav.vue"),
  "navigation-side-nav-help-item-list": () => import("/overbookd/apps/web/layouts/navigation/SideNavHelpItemList.vue"),
  "navigation-side-nav-page-item": () => import("/overbookd/apps/web/layouts/navigation/SideNavPageItem.vue"),
  "navigation-side-nav-page-list": () => import("/overbookd/apps/web/layouts/navigation/SideNavPageList.vue"),
  "navigation-side-nav-search-field": () => import("/overbookd/apps/web/layouts/navigation/SideNavSearchField.vue")
}