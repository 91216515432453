import payload_plugin_RXnXvnMNoi from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_OHWCM7Sy5H from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rc294LWexj from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qUUEQMH827 from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1t7w0ciRSl from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lzqhYcpKkW from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1GACrqOX42 from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GOxDGbPTPs from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0MgjReGFPh from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_dexCSomBMC from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  payload_plugin_RXnXvnMNoi,
  revive_payload_client_OHWCM7Sy5H,
  unhead_rc294LWexj,
  router_qUUEQMH827,
  payload_client_1t7w0ciRSl,
  navigation_repaint_client_lzqhYcpKkW,
  check_outdated_build_client_1GACrqOX42,
  chunk_reload_client_GOxDGbPTPs,
  plugin_vue3_0MgjReGFPh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dexCSomBMC,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]