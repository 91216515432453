var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/index.ts
var HAVE_PERSONAL_ACCOUNT = "have-personal-account";
var AFFECT_TEAM = "affect-team";
var BE_AFFECTED = "be-affected";
var WRITE_GEAR_CATALOG = "write-gear-catalog";
var READ_GEAR_CATALOG = "read-gear-catalog";
var READ_SIGNAGE_CATALOG = "read-signage-catalog";
var WRITE_SIGNAGE_CATALOG = "write-signage-catalog";
var VIEW_GEAR_DASHBOARD = "view-gear-dashboard";
var VIEW_LOCATION = "view-location";
var MANAGE_LOCATION = "manage-location";
var READ_INVENTORY = "read-inventory";
var WRITE_INVENTORY = "write-inventory";
var MANAGE_PERSONAL_ACCOUNTS = "manage-personal-accounts";
var MANAGE_USERS = "manage-users";
var AFFECT_VOLUNTEER = "affect-volunteer";
var MANAGE_CONFIG = "manage-config";
var VALIDATE_FA = "validate-fa";
var VALIDATE_FT = "validate-ft";
var READ_ANIMATION_TO_PUBLISH = "read-animation-to-publish";
var READ_FA = "read-fa";
var WRITE_FA = "write-fa";
var READ_FT = "read-ft";
var WRITE_FT = "write-ft";
var FORCE_WRITE_FT = "force-write-ft";
var VIEW_FESTIVAL_EVENTS_STATS = "view-festival-events-stats";
var VIEW_TIMELINE = "view-timeline";
var ASK_FOR_HELP = "ask-for-help";
var VIEW_PLANNING = "view-planning";
var DOWNLOAD_PLANNING = "download-planning";
var SYNC_PLANNING = "sync-planning";
var ENROLL_HARD = "enroll-hard";
var ENROLL_SOFT = "enroll-soft";
var VIEW_VOLUNTEER = "view-volunteer";
var VIEW_VOLUNTEER_DETAILS = "view-volunteer-details";
var SEND_MAIL_TEST = "send-mail-test";
var MANAGE_TEAMS = "manage-teams";
var MANAGE_PERMISSIONS = "manage-permissions";
var VIEW_DELETED_FT = "view-deleted-ft";
var MANAGE_ADMINS = "manage-admins";
var MANAGE_CONTRIBUTIONS = "manage-contributions";
var PAY_CONTRIBUTION = "pay-contribution";
var OFFER_SHARED_MEAL = "offer-shared-meal";
var SHOTGUN_SHARED_MEAL = "shotgun-shared-meal";
var PURCHASE_GEARS = "purchase-gears";
var BORROW_GEARS = "borrow-gears";
var MANAGE_CHARISMA_EVENTS = "manage-charisma-events";
var SET_FAVORITE_PAGES = "set-favorite-pages";
var VIEW_SECURITY_DASHBOARD = "view-security-dashboard";
var permissions = [
  HAVE_PERSONAL_ACCOUNT,
  AFFECT_TEAM,
  BE_AFFECTED,
  WRITE_GEAR_CATALOG,
  READ_GEAR_CATALOG,
  VIEW_GEAR_DASHBOARD,
  READ_SIGNAGE_CATALOG,
  WRITE_SIGNAGE_CATALOG,
  VIEW_LOCATION,
  READ_INVENTORY,
  WRITE_INVENTORY,
  MANAGE_PERSONAL_ACCOUNTS,
  MANAGE_USERS,
  AFFECT_VOLUNTEER,
  MANAGE_CONFIG,
  MANAGE_LOCATION,
  VALIDATE_FA,
  VALIDATE_FT,
  READ_ANIMATION_TO_PUBLISH,
  READ_FA,
  WRITE_FA,
  READ_FT,
  WRITE_FT,
  FORCE_WRITE_FT,
  VIEW_FESTIVAL_EVENTS_STATS,
  VIEW_TIMELINE,
  ASK_FOR_HELP,
  VIEW_PLANNING,
  DOWNLOAD_PLANNING,
  SYNC_PLANNING,
  ENROLL_HARD,
  ENROLL_SOFT,
  VIEW_VOLUNTEER,
  VIEW_VOLUNTEER_DETAILS,
  SEND_MAIL_TEST,
  MANAGE_TEAMS,
  MANAGE_PERMISSIONS,
  VIEW_DELETED_FT,
  MANAGE_ADMINS,
  MANAGE_CONTRIBUTIONS,
  PAY_CONTRIBUTION,
  OFFER_SHARED_MEAL,
  SHOTGUN_SHARED_MEAL,
  PURCHASE_GEARS,
  BORROW_GEARS,
  MANAGE_CHARISMA_EVENTS,
  SET_FAVORITE_PAGES,
  VIEW_SECURITY_DASHBOARD
];
function isPermission(permission) {
  return permissions.some((p) => p === permission);
}
__name(isPermission, "isPermission");
export {
  AFFECT_TEAM,
  AFFECT_VOLUNTEER,
  ASK_FOR_HELP,
  BE_AFFECTED,
  BORROW_GEARS,
  DOWNLOAD_PLANNING,
  ENROLL_HARD,
  ENROLL_SOFT,
  FORCE_WRITE_FT,
  HAVE_PERSONAL_ACCOUNT,
  MANAGE_ADMINS,
  MANAGE_CHARISMA_EVENTS,
  MANAGE_CONFIG,
  MANAGE_CONTRIBUTIONS,
  MANAGE_LOCATION,
  MANAGE_PERMISSIONS,
  MANAGE_PERSONAL_ACCOUNTS,
  MANAGE_TEAMS,
  MANAGE_USERS,
  OFFER_SHARED_MEAL,
  PAY_CONTRIBUTION,
  PURCHASE_GEARS,
  READ_ANIMATION_TO_PUBLISH,
  READ_FA,
  READ_FT,
  READ_GEAR_CATALOG,
  READ_INVENTORY,
  READ_SIGNAGE_CATALOG,
  SEND_MAIL_TEST,
  SET_FAVORITE_PAGES,
  SHOTGUN_SHARED_MEAL,
  SYNC_PLANNING,
  VALIDATE_FA,
  VALIDATE_FT,
  VIEW_DELETED_FT,
  VIEW_FESTIVAL_EVENTS_STATS,
  VIEW_GEAR_DASHBOARD,
  VIEW_LOCATION,
  VIEW_PLANNING,
  VIEW_SECURITY_DASHBOARD,
  VIEW_TIMELINE,
  VIEW_VOLUNTEER,
  VIEW_VOLUNTEER_DETAILS,
  WRITE_FA,
  WRITE_FT,
  WRITE_GEAR_CATALOG,
  WRITE_INVENTORY,
  WRITE_SIGNAGE_CATALOG,
  isPermission,
  permissions
};
