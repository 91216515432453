// src/mime.ts
var JSON = "application/json";
var CSV = "text/csv";
var ICAL = "text/calendar";
var PDF = "application/pdf";
export {
  CSV,
  ICAL,
  JSON,
  PDF
};
