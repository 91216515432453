<template>
  <NuxtLayout>
    <NuxtPage />
    <SnackBar />
  </NuxtLayout>
</template>

<style lang="scss">
:root {
  font-size: 0.9rem;
}
</style>
