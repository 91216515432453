var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/common/action.ts
var CREATED = "CREATED";
var COMMENTED = "COMMENTED";
var READY_TO_REVIEW = "READY_TO_REVIEW";
var APPROVED = "APPROVED";
var REJECTED = "REJECTED";
var RESET_REVIEW = "RESET_REVIEW";
var ASSIGNMENT_STARTED = "ASSIGNMENT_STARTED";
var FORCED_UPDATE = "FORCED_UPDATE";

// src/common/inquiry-request.ts
var BENNE_COLLETTE_BESSON = "Benne Collette Besson";
var BENNE_PARKING_K_FET = "Benne Parking K-fet";
var PARKING_EIFFEL = "Parking Eiffel";
var CREUX_GCU = "Creux GCU";
var CREUX_GM = "Creux GM";
var CAVE_E = "Cave E";
var CLUB_ROCK = "Club Rock";
var CONTENUR_24H = "Conteneur 24h";
var CONTENEUR_KARNA = "Conteneur Karna";
var CONTENEUR_PARKING_K_FET = "Conteneur Parking K-fet";
var CONTENEUR_SCENE_ROOTS = "Conteneur Sc\xE8ne Roots";
var HALL_DES_HUMANITES = "Hall des Humanit\xE9s";
var LOCAL_24H = "Local 24h";
var MAGASIN = "Magasin";
var MDE = "MdE";
var SALLE_MONTREAL = "Salle Montr\xE9al";
var SALLE_RENE_CHAR = "Salle Ren\xE9 Char";
var NON_STOCKE = "Non stock\xE9";
var QG_ORGA = "QG Orga";
var BACKLINE = "Backline";
var SALLE_CRLA = "Salle CRL-A Humanit\xE9s";
var LIVRE_PAR_LOGISTIQUE = "Livr\xE9 par l'\xE9quipe logistique";
var LIVRE_PAR_COM = "Livr\xE9 par une com";
var drives = [
  BENNE_COLLETTE_BESSON,
  BENNE_PARKING_K_FET,
  PARKING_EIFFEL,
  CREUX_GCU,
  CREUX_GM,
  CAVE_E,
  CLUB_ROCK,
  CONTENUR_24H,
  CONTENEUR_KARNA,
  CONTENEUR_PARKING_K_FET,
  CONTENEUR_SCENE_ROOTS,
  HALL_DES_HUMANITES,
  LOCAL_24H,
  MAGASIN,
  MDE,
  SALLE_MONTREAL,
  SALLE_RENE_CHAR,
  NON_STOCKE,
  QG_ORGA,
  BACKLINE,
  SALLE_CRLA,
  LIVRE_PAR_LOGISTIQUE,
  LIVRE_PAR_COM
];
function isAssignedToDrive(request) {
  return Object.hasOwn(request, "drive");
}
__name(isAssignedToDrive, "isAssignedToDrive");

// src/common/review.ts
var REVIEWING = "REVIEWING";
var NOT_ASKING_TO_REVIEW = "NOT_ASKING_TO_REVIEW";
var communication = "communication";
var humain = "humain";
var signa = "signa";
var secu = "secu";
var matos = "matos";
var elec = "elec";
var barrieres = "barrieres";
function isRefusedReviews(reviews) {
  return Object.values(reviews).some((review) => review === REJECTED);
}
__name(isRefusedReviews, "isRefusedReviews");
function isValidatedReviews(reviews) {
  return Object.values(reviews).every(
    (review) => review === APPROVED || review === NOT_ASKING_TO_REVIEW
  );
}
__name(isValidatedReviews, "isValidatedReviews");

// src/festival-event.ts
import {
  DRAFT,
  IN_REVIEW,
  REFUSED,
  VALIDATED
} from "@overbookd/festival-event-constants";
var FestivalEventError = class extends Error {
  static {
    __name(this, "FestivalEventError");
  }
};
function isDraft(event) {
  return event.status === DRAFT;
}
__name(isDraft, "isDraft");
function isInReview(event) {
  return event.status === IN_REVIEW;
}
__name(isInReview, "isInReview");
function isRefused(event) {
  return event.status === REFUSED;
}
__name(isRefused, "isRefused");
function isValidated(event) {
  return event.status === VALIDATED;
}
__name(isValidated, "isValidated");

// src/festival-activity/festival-activity.error.ts
function buildFestivalActivityNotFoundErrorMessage(id) {
  return `La fiche activit\xE9 #${id} n'a pas \xE9t\xE9 trouv\xE9e`;
}
__name(buildFestivalActivityNotFoundErrorMessage, "buildFestivalActivityNotFoundErrorMessage");
var FREE_PASS_MUST_BE_POSITIVE_ERROR_MESSAGE = "Le nombre de laissez passer doit \xEAtre sup\xE9rieur ou \xE9gale \xE0 0";
var TIME_WINDOW_ALREADY_EXISTS_ERROR_MESSAGE = "Une cr\xE9neau existe d\xE9j\xE0 \xE0 ces heures";
var CONTRACTOR_NOT_FOUND_ERROR_MESSAGE = "Ce prestataire n'existe pas dans la fiche activit\xE9";
var SIGNAGE_NOT_FOUND_ERROR_MESSAGE = "Cette signal\xE9tique n'existe pas dans la fiche activit\xE9";
var SIGNAGE_ALREADY_EXISTS_ERROR_MESSAGE = "Une signal\xE9tique similaire existe d\xE9j\xE0 dans la fiche activit\xE9";
var ELECTRICITY_SUPPLY_NOT_FOUND_ERROR_MESSAGE = "Cette demande d'elec n'existe pas dans la fiche activit\xE9";
var ELECTRICITY_SUPPLY_ALREADY_EXISTS_ERROR_MESSAGE = "Une demande d'elec similaire existe d\xE9j\xE0 dans la fiche activit\xE9";
var INQUIRY_ALREADY_EXISTS_ERROR_MESSAGE = "Une demande de matos existe d\xE9j\xE0 pour";
var FestivalActivityError = class extends FestivalEventError {
  static {
    __name(this, "FestivalActivityError");
  }
};
var FestivalActivityNotFound = class extends FestivalActivityError {
  static {
    __name(this, "FestivalActivityNotFound");
  }
  constructor(id) {
    super(buildFestivalActivityNotFoundErrorMessage(id));
  }
};
var TimeWindowAlreadyExists = class extends FestivalActivityError {
  static {
    __name(this, "TimeWindowAlreadyExists");
  }
  constructor() {
    super(TIME_WINDOW_ALREADY_EXISTS_ERROR_MESSAGE);
  }
};
var FreePassMustBePositive = class extends FestivalActivityError {
  static {
    __name(this, "FreePassMustBePositive");
  }
  constructor() {
    super(FREE_PASS_MUST_BE_POSITIVE_ERROR_MESSAGE);
  }
};
var ContractorNotFound = class extends FestivalActivityError {
  static {
    __name(this, "ContractorNotFound");
  }
  constructor() {
    super(CONTRACTOR_NOT_FOUND_ERROR_MESSAGE);
  }
};
var SignageNotFound = class extends FestivalActivityError {
  static {
    __name(this, "SignageNotFound");
  }
  constructor() {
    super(SIGNAGE_NOT_FOUND_ERROR_MESSAGE);
  }
};
var SignageAlreadyExists = class extends FestivalActivityError {
  static {
    __name(this, "SignageAlreadyExists");
  }
  constructor() {
    super(SIGNAGE_ALREADY_EXISTS_ERROR_MESSAGE);
  }
};
var ElectricitySupplyNotFound = class extends FestivalActivityError {
  static {
    __name(this, "ElectricitySupplyNotFound");
  }
  constructor() {
    super(ELECTRICITY_SUPPLY_NOT_FOUND_ERROR_MESSAGE);
  }
};
var ElectricitySupplyAlreadyExists = class extends FestivalActivityError {
  static {
    __name(this, "ElectricitySupplyAlreadyExists");
  }
  constructor() {
    super(ELECTRICITY_SUPPLY_ALREADY_EXISTS_ERROR_MESSAGE);
  }
};
var InquiryAlreadyExists = class extends FestivalActivityError {
  static {
    __name(this, "InquiryAlreadyExists");
  }
  constructor(gear) {
    super(`${INQUIRY_ALREADY_EXISTS_ERROR_MESSAGE} ${gear}`);
  }
};

// src/festival-activity/sections/inquiry.ts
var MATOS = "matos";
var BARRIERES = "barrieres";
var ELEC = "elec";

// src/festival-activity/sections/supply.ts
var PC16_Prise_classique = "PC16_Prise_classique";
var P17_16A_MONO = "P17_16A_MONO";
var P17_16A_TRI = "P17_16A_TRI";
var P17_16A_TETRA = "P17_16A_TETRA";
var P17_32A_MONO = "P17_32A_MONO";
var P17_32A_TRI = "P17_32A_TRI";
var P17_32A_TETRA = "P17_32A_TETRA";
var P17_63A_MONO = "P17_63A_MONO";
var P17_63A_TRI = "P17_63A_TRI";
var P17_63A_TETRA = "P17_63A_TETRA";
var P17_125A_TETRA = "P17_125A_TETRA";

// src/festival-activity/sections/signa.ts
var BACHE = "BACHE";
var PANNEAU = "PANNEAU";
var AFFICHE = "AFFICHE";
var signageTypes = {
  BACHE,
  PANNEAU,
  AFFICHE
};
function isLinkedToCatalogItem(request) {
  return Object.hasOwn(request, "catalogItem");
}
__name(isLinkedToCatalogItem, "isLinkedToCatalogItem");

// src/festival-activity/creation/creation.ts
import { numberGenerator } from "@overbookd/list";
import { DRAFT as DRAFT2 } from "@overbookd/festival-event-constants";

// src/festival-activity/festival-activity.event.ts
var FestivalActivityKeyEvents = class {
  static {
    __name(this, "FestivalActivityKeyEvents");
  }
  static created(by) {
    const at = this.computeAt();
    return { action: CREATED, by, at, description: "FA cr\xE9\xE9e" };
  }
  static readyToReview(by) {
    const at = this.computeAt();
    const description = "Demande de relecture de la FA";
    return { action: READY_TO_REVIEW, by, at, description };
  }
  static approved(by) {
    const at = this.computeAt();
    const description = "FA approuv\xE9e";
    return { action: APPROVED, by, at, description };
  }
  static rejected(by, reason) {
    const at = this.computeAt();
    const description = `FA rejet\xE9e pour la raison suivante: ${reason}`;
    return { action: REJECTED, by, at, description };
  }
  static computeAt() {
    const at = /* @__PURE__ */ new Date();
    at.setMilliseconds(0);
    return at;
  }
};

// src/festival-activity/creation/creation.ts
var CreateFestivalActivity = class {
  constructor(festivalActivities, startId = 1) {
    this.festivalActivities = festivalActivities;
    this.idGenerator = numberGenerator(startId);
  }
  static {
    __name(this, "CreateFestivalActivity");
  }
  idGenerator;
  async create({ name, author }) {
    const activity = {
      id: this.generateId(),
      status: DRAFT2,
      general: this.generateGeneralSection(name),
      inCharge: this.generateInChargeSection(author),
      signa: this.generateSignaSection(),
      security: this.generateSecuritySection(),
      supply: this.generateSupplySection(),
      inquiry: this.generateInquirySection(),
      feedbacks: [],
      history: [FestivalActivityKeyEvents.created(author)],
      tasks: []
    };
    return this.festivalActivities.create(activity);
  }
  generateId() {
    return this.idGenerator.next().value;
  }
  generateGeneralSection(name) {
    return {
      name,
      categories: [],
      description: null,
      toPublish: false,
      isFlagship: false,
      photoLink: null,
      timeWindows: []
    };
  }
  generateInChargeSection(author) {
    return {
      adherent: author,
      team: null,
      contractors: []
    };
  }
  generateSignaSection() {
    return {
      location: null,
      signages: []
    };
  }
  generateSecuritySection() {
    return {
      specialNeed: null,
      freePass: 0
    };
  }
  generateSupplySection() {
    return {
      electricity: [],
      water: null
    };
  }
  generateInquirySection() {
    return {
      timeWindows: [],
      gears: [],
      electricity: [],
      barriers: []
    };
  }
};

// src/festival-activity/creation/festival-activities.inmemory.ts
var InMemoryCreateFestivalActivityRepository = class {
  constructor(festivalActivities = []) {
    this.festivalActivities = festivalActivities;
  }
  static {
    __name(this, "InMemoryCreateFestivalActivityRepository");
  }
  create(activity) {
    this.festivalActivities = [...this.festivalActivities, activity];
    return Promise.resolve(activity);
  }
};

// src/festival-activity/festival-activity.factory.ts
import { numberGenerator as numberGenerator2 } from "@overbookd/list";
import {
  DRAFT as DRAFT3,
  IN_REVIEW as IN_REVIEW2,
  REFUSED as REFUSED2,
  VALIDATED as VALIDATED2
} from "@overbookd/festival-event-constants";

// src/festival-activity/festival-activity.fake.ts
var lea = {
  id: 2,
  lastname: "Mouyno",
  firstname: "Lea"
};

// src/festival-activity/festival-activity.factory.ts
function defaultDraft(id, name) {
  return {
    id,
    general: {
      name,
      description: null,
      categories: [],
      toPublish: false,
      photoLink: null,
      isFlagship: false,
      timeWindows: []
    },
    status: DRAFT3,
    inCharge: {
      adherent: lea,
      team: null,
      contractors: []
    },
    signa: { location: null, signages: [] },
    security: {
      specialNeed: null,
      freePass: 0
    },
    supply: {
      electricity: [],
      water: null
    },
    inquiry: {
      timeWindows: [],
      gears: [],
      electricity: [],
      barriers: []
    },
    feedbacks: [],
    history: [FestivalActivityKeyEvents.created(lea)],
    tasks: []
  };
}
__name(defaultDraft, "defaultDraft");

// src/festival-activity/preparation/prepare-in-review-festival-activity.ts
import {
  IN_REVIEW as IN_REVIEW3,
  REFUSED as REFUSED3,
  VALIDATED as VALIDATED3
} from "@overbookd/festival-event-constants";

// src/festival-activity/preparation/section-aggregates/electricity-supplies.ts
import { updateItemToList } from "@overbookd/list";
import { SlugifyService } from "@overbookd/slugify";
var ElectricitySupplies = class _ElectricitySupplies {
  constructor(electricitySupplies) {
    this.electricitySupplies = electricitySupplies;
  }
  static {
    __name(this, "ElectricitySupplies");
  }
  get entries() {
    return this.electricitySupplies;
  }
  static build(supplies) {
    return new _ElectricitySupplies(supplies);
  }
  add(form) {
    const id = this.generateElectricitySupplyId(form.device, form.connection);
    const comment = form.comment ?? null;
    const supply = { ...form, id, comment };
    this.throwIfAlreadyExists(id);
    return new _ElectricitySupplies([...this.electricitySupplies, supply]);
  }
  update(form) {
    const currentSupplyIndex = this.electricitySupplies.findIndex(
      (es) => es.id === form.id
    );
    const currentSupply = this.electricitySupplies.at(currentSupplyIndex);
    if (currentSupplyIndex === -1 || !currentSupply) {
      throw new ElectricitySupplyNotFound();
    }
    const updatedSupply = this.generateUpdatedSupply(currentSupply, form);
    if (currentSupply.id !== updatedSupply.id) {
      this.throwIfAlreadyExists(updatedSupply.id);
    }
    const electricitySupplies = updateItemToList(
      this.electricitySupplies,
      currentSupplyIndex,
      updatedSupply
    );
    return new _ElectricitySupplies(electricitySupplies);
  }
  remove(id) {
    return new _ElectricitySupplies(
      this.electricitySupplies.filter((es) => es.id !== id)
    );
  }
  generateElectricitySupplyId(device, connection) {
    const supplyId = SlugifyService.apply(`${device} ${connection}`);
    return supplyId;
  }
  throwIfAlreadyExists(id) {
    const alreadyExists = this.electricitySupplies.some((es) => es.id === id);
    if (alreadyExists) throw new ElectricitySupplyAlreadyExists();
  }
  generateUpdatedSupply(previousSupply, form) {
    const updatedSupply = {
      ...previousSupply,
      connection: form.connection ?? previousSupply.connection,
      device: form.device ?? previousSupply.device,
      power: form.power ?? previousSupply.power,
      count: form.count ?? previousSupply.count,
      comment: form.comment === void 0 ? previousSupply.comment : form.comment
    };
    const id = this.generateElectricitySupplyId(
      updatedSupply.device,
      updatedSupply.connection
    );
    return { ...updatedSupply, id };
  }
};

// src/festival-activity/preparation/section-aggregates/contractors.ts
import { updateItemToList as updateItemToList2 } from "@overbookd/list";
var Contractors = class _Contractors {
  constructor(contractors) {
    this.contractors = contractors;
  }
  static {
    __name(this, "Contractors");
  }
  get entries() {
    return this.contractors;
  }
  static build(contractors) {
    return new _Contractors(contractors);
  }
  add(form) {
    const id = this.generateContractorId();
    const contractor = {
      ...form,
      id,
      email: form.email ?? null,
      company: form.company ?? null,
      comment: form.comment ?? null
    };
    return new _Contractors([...this.contractors, contractor]);
  }
  update(contractor) {
    const currentContractorIndex = this.contractors.findIndex(
      (c) => c.id === contractor.id
    );
    const currentContractor = this.contractors.at(currentContractorIndex);
    if (currentContractorIndex === -1 || !currentContractor) {
      throw new ContractorNotFound();
    }
    const updatedContractor = this.generateUpdatedContractor(
      currentContractor,
      contractor
    );
    const contractors = updateItemToList2(
      this.contractors,
      currentContractorIndex,
      updatedContractor
    );
    return new _Contractors(contractors);
  }
  remove(id) {
    return new _Contractors(this.contractors.filter((c) => c.id !== id));
  }
  generateContractorId() {
    const lastContractorId = this.contractors.at(-1)?.id ?? 0;
    return lastContractorId + 1;
  }
  generateUpdatedContractor(previousContractor, form) {
    const updatedContractor = {
      ...previousContractor,
      firstname: form.firstname ?? previousContractor.firstname,
      lastname: form.lastname ?? previousContractor.lastname,
      phone: form.phone ?? previousContractor.phone,
      email: form.email === void 0 ? previousContractor.email : form.email,
      company: form.company === void 0 ? previousContractor.company : form.company,
      comment: form.comment === void 0 ? previousContractor.comment : form.comment
    };
    return updatedContractor;
  }
};

// src/festival-activity/preparation/section-aggregates/time-windows.ts
import { Duration, Period } from "@overbookd/time";
var TimeWindows = class _TimeWindows {
  constructor(timeWindows) {
    this.timeWindows = timeWindows;
  }
  static {
    __name(this, "TimeWindows");
  }
  get entries() {
    return this.timeWindows;
  }
  static build(timeWindows) {
    return new _TimeWindows(timeWindows);
  }
  add(period) {
    const { start, end } = Period.init(period);
    const id = this.generateTimeWindowId({ start, end });
    const timeWindow = { id, start, end };
    const alreadyExists = this.timeWindows.some((tw) => tw.id === id);
    if (alreadyExists) throw new TimeWindowAlreadyExists();
    return new _TimeWindows([
      timeWindow,
      ...this.timeWindows
    ]);
  }
  remove(id) {
    return new _TimeWindows(this.timeWindows.filter((tw) => tw.id !== id));
  }
  generateTimeWindowId(period) {
    const { start, end } = period;
    const startMinutes = Duration.ms(start.getTime()).inMinutes;
    const endMinutes = Duration.ms(end.getTime()).inMinutes;
    return `${startMinutes}-${endMinutes}`;
  }
};

// src/festival-activity/preparation/prepare-in-review-festival-activity.ts
import { hasAtLeastOneItem } from "@overbookd/list";

// src/festival-activity/preparation/section-aggregates/inquiries.ts
import { updateItemToList as updateItemToList3 } from "@overbookd/list";

// src/festival-task/festival-task.error.ts
var FestivalTaskError = class extends FestivalEventError {
  static {
    __name(this, "FestivalTaskError");
  }
};
var FestivalTaskNotFound = class extends FestivalTaskError {
  static {
    __name(this, "FestivalTaskNotFound");
  }
  constructor(ftId) {
    const message = `La fiche t\xE2che #${ftId} n'a pas \xE9t\xE9 trouv\xE9e`;
    super(message);
  }
};
var SplitDurationIsNotPeriodDivider = class extends FestivalTaskError {
  static {
    __name(this, "SplitDurationIsNotPeriodDivider");
  }
  constructor(duration) {
    const message = `La p\xE9riode n'est pas divisible en ${duration.inHours} heures`;
    super(message);
  }
};
var MobilizationAlreadyExist = class extends FestivalTaskError {
  static {
    __name(this, "MobilizationAlreadyExist");
  }
  constructor() {
    super("Un autre cr\xE9neau existe sur cette m\xEAme p\xE9riode");
  }
};
var MobilizationNotFound = class extends FestivalTaskError {
  static {
    __name(this, "MobilizationNotFound");
  }
  constructor() {
    super("Il n'y a pas de mobilisation correspondante");
  }
};
var GearAlreadyRequested = class extends FestivalTaskError {
  static {
    __name(this, "GearAlreadyRequested");
  }
  constructor(name) {
    const message = `Une demande de matos existe d\xE9j\xE0 pour ${name}`;
    super(message);
  }
};
var FestivalTaskNotValidated = class extends FestivalTaskError {
  static {
    __name(this, "FestivalTaskNotValidated");
  }
  constructor(ftId) {
    super(`La fiche t\xE2che #${ftId} n'est pas encore valid\xE9e`);
  }
};
var CANT_START_ASSIGNMENT_ERROR_MESSAGE = "Impossible de d\xE9marrer l'affectation pour cette FT.";
var AT_LEAST_ONE_VOLUNTEER_IS_NOT_AVAILABLE = "Au moins un des b\xE9n\xE9voles n'est pas disponible.";
var ReadyToAssignError = class extends FestivalTaskError {
  static {
    __name(this, "ReadyToAssignError");
  }
  constructor() {
    const message = `${CANT_START_ASSIGNMENT_ERROR_MESSAGE}
- ${AT_LEAST_ONE_VOLUNTEER_IS_NOT_AVAILABLE}`;
    super(message);
  }
};
var ForceUpdateError = class _ForceUpdateError extends FestivalTaskError {
  static {
    __name(this, "ForceUpdateError");
  }
  static notReadyToAssign(ftId) {
    const notReadyToAssign = `La ft #${ftId} n'est pas en affectation`;
    return new _ForceUpdateError(notReadyToAssign);
  }
};

// src/festival-activity/preparation/section-aggregates/inquiries.ts
var AlreadyInitialized = class extends FestivalActivityError {
  static {
    __name(this, "AlreadyInitialized");
  }
  constructor() {
    super("La section Demande de matos a d\xE9j\xE0 \xE9t\xE9 initialis\xE9e");
  }
};
var NotYetInitialized = class extends FestivalActivityError {
  static {
    __name(this, "NotYetInitialized");
  }
  constructor() {
    super("La section Demande de matos n'a pas encore \xE9t\xE9 initialis\xE9e.");
  }
};
var CantRemoveLastTimeWindow = class extends FestivalActivityError {
  static {
    __name(this, "CantRemoveLastTimeWindow");
  }
  constructor() {
    super(
      "Il s'agit du dernier cr\xE9neau matos. Il n'est pas possible de le supprimer"
    );
  }
};
var CantRemoveLastRequest = class extends FestivalActivityError {
  static {
    __name(this, "CantRemoveLastRequest");
  }
  constructor() {
    super(
      "Il s'agit de la derni\xE8re demande de matos. Il n'est pas possible de la supprimer"
    );
  }
};
var Inquiries = class _Inquiries {
  constructor(timeWindows, gears, barriers, electricity) {
    this.timeWindows = timeWindows;
    this.gears = gears;
    this.barriers = barriers;
    this.electricity = electricity;
  }
  static {
    __name(this, "Inquiries");
  }
  get inquiry() {
    return {
      timeWindows: this.timeWindows.entries,
      gears: this.gears.entries,
      barriers: this.barriers.entries,
      electricity: this.electricity.entries
    };
  }
  static build({
    timeWindows,
    gears,
    barriers,
    electricity
  }) {
    return new _Inquiries(
      TimeWindows.build(timeWindows),
      InquiryRequests.build(gears),
      InquiryRequests.build(barriers),
      InquiryRequests.build(electricity)
    );
  }
  static init() {
    return new _Inquiries(
      TimeWindows.build([]),
      InquiryRequests.build([]),
      InquiryRequests.build([]),
      InquiryRequests.build([])
    );
  }
  static alreadyInitialized(inquiry) {
    const hasTimeWindows = inquiry.timeWindows.length > 0;
    const requests = [
      ...inquiry.gears,
      ...inquiry.barriers,
      ...inquiry.electricity
    ];
    const hasRequests = requests.length > 0;
    return hasTimeWindows || hasRequests;
  }
  addRequest({ owner, ...request }) {
    switch (owner) {
      case MATOS:
        return new _Inquiries(
          this.timeWindows,
          this.gears.add(request),
          this.barriers,
          this.electricity
        );
      case BARRIERES:
        return new _Inquiries(
          this.timeWindows,
          this.gears,
          this.barriers.add(request),
          this.electricity
        );
      case ELEC:
        return new _Inquiries(
          this.timeWindows,
          this.gears,
          this.barriers,
          this.electricity.add(request)
        );
    }
  }
  removeRequest(slug) {
    return new _Inquiries(
      this.timeWindows,
      this.gears.remove(slug),
      this.barriers.remove(slug),
      this.electricity.remove(slug)
    );
  }
  addTimeWindow(timeWindow) {
    return new _Inquiries(
      this.timeWindows.add(timeWindow),
      this.gears,
      this.barriers,
      this.electricity
    );
  }
  removeTimeWindow(id) {
    return new _Inquiries(
      this.timeWindows.remove(id),
      this.gears,
      this.barriers,
      this.electricity
    );
  }
  assignDrive({ owner, ...assign }) {
    switch (owner) {
      case MATOS:
        return new _Inquiries(
          this.timeWindows,
          this.gears.assignDrive(assign),
          this.barriers,
          this.electricity
        );
      case BARRIERES:
        return new _Inquiries(
          this.timeWindows,
          this.gears,
          this.barriers.assignDrive(assign),
          this.electricity
        );
      case ELEC:
        return new _Inquiries(
          this.timeWindows,
          this.gears,
          this.barriers,
          this.electricity.assignDrive(assign)
        );
    }
  }
};
var InquiryRequests = class _InquiryRequests {
  constructor(inquiries) {
    this.inquiries = inquiries;
  }
  static {
    __name(this, "InquiryRequests");
  }
  get entries() {
    return this.inquiries;
  }
  static build(inquiries) {
    return new _InquiryRequests(inquiries);
  }
  add({
    slug,
    quantity,
    name
  }) {
    const inquiry = { slug, quantity, name };
    const alreadyExists = this.inquiries.some(
      (inquiry2) => inquiry2.slug === slug
    );
    if (alreadyExists) throw new InquiryAlreadyExists(name);
    return new _InquiryRequests([inquiry, ...this.inquiries]);
  }
  remove(slug) {
    return new _InquiryRequests(
      this.inquiries.filter((inquiry) => inquiry.slug !== slug)
    );
  }
  assignDrive({ slug, drive }) {
    const inquiryIndex = this.inquiries.findIndex(
      (inquiry2) => inquiry2.slug === slug
    );
    const inquiry = this.inquiries.at(inquiryIndex);
    if (inquiryIndex === -1 || !inquiry) {
      throw new FestivalTaskError("Aucune demande de matos ne corresond");
    }
    const inquiries = updateItemToList3(this.inquiries, inquiryIndex, {
      ...inquiry,
      drive
    });
    return new _InquiryRequests(inquiries);
  }
};

// src/festival-activity/preparation/section-aggregates/signages.ts
import { SlugifyService as SlugifyService2 } from "@overbookd/slugify";
import { updateItemToList as updateItemToList4 } from "@overbookd/list";
var LocationIsRequired = class extends FestivalActivityError {
  static {
    __name(this, "LocationIsRequired");
  }
  constructor() {
    super("Le lieu ne peut pas \xEAtre remis \xE0 zero");
  }
};
var Signages = class _Signages {
  constructor(signages) {
    this.signages = signages;
  }
  static {
    __name(this, "Signages");
  }
  get entries() {
    return this.signages;
  }
  static build(signages) {
    return new _Signages(signages);
  }
  add(form) {
    const id = this.generateSignageId(form.type, form.text, form.size);
    const signage = {
      ...form,
      id,
      comment: form.comment ?? null
    };
    this.throwIfAlreadyExists(id);
    return new _Signages([...this.signages, signage]);
  }
  update(form) {
    const currentSignageIndex = this.signages.findIndex(
      (signage) => signage.id === form.id
    );
    const currentSignage = this.signages.at(currentSignageIndex);
    if (currentSignageIndex === -1 || !currentSignage) {
      throw new SignageNotFound();
    }
    const updatedSignage = this.generateUpdatedSignage(currentSignage, form);
    if (updatedSignage.id !== currentSignage.id) {
      this.throwIfAlreadyExists(updatedSignage.id);
    }
    const signages = updateItemToList4(
      this.signages,
      currentSignageIndex,
      updatedSignage
    );
    return new _Signages(signages);
  }
  remove(id) {
    return new _Signages(this.signages.filter((s) => s.id !== id));
  }
  linkCatalogItem({
    signageId,
    catalogItem
  }) {
    const signageIndex = this.signages.findIndex(
      (inquiry) => inquiry.id === signageId
    );
    const signage = this.signages.at(signageIndex);
    if (signageIndex === -1 || !signage) throw new SignageNotFound();
    const signages = updateItemToList4(this.signages, signageIndex, {
      ...signage,
      catalogItem
    });
    return new _Signages(signages);
  }
  generateUpdatedSignage(previousSignage, form) {
    const updatedSignage = {
      ...previousSignage,
      text: form.text ?? previousSignage.text,
      quantity: form.quantity ?? previousSignage.quantity,
      size: form.size ?? previousSignage.size,
      type: form.type ?? previousSignage.type,
      comment: form.comment === void 0 ? previousSignage.comment : form.comment
    };
    const id = this.generateSignageId(
      updatedSignage.type,
      updatedSignage.text,
      updatedSignage.size
    );
    return { ...updatedSignage, id };
  }
  generateSignageId(type, text, size) {
    return SlugifyService2.apply(`${type} ${text} ${size}`);
  }
  throwIfAlreadyExists(id) {
    const alreadyExists = this.signages.some((signage) => signage.id === id);
    if (alreadyExists) throw new SignageAlreadyExists();
  }
};

// src/festival-activity/sections/general.ts
function isPrivate(general) {
  return general.toPublish === false;
}
__name(isPrivate, "isPrivate");

// src/common/festival-event.ts
var FA = "FA";

// src/common/review.error.ts
var NotAskingToReview = class extends FestivalEventError {
  static {
    __name(this, "NotAskingToReview");
  }
  constructor(eventId, team, identifier) {
    const message = `La ${identifier} #${eventId} n'est pas \xE0 valider par l'\xE9quipe ${team}`;
    super(message);
  }
};
var CantAskForReview = class extends FestivalEventError {
  static {
    __name(this, "CantAskForReview");
  }
  constructor(eventId, identifier = FA) {
    const eventName = identifier === FA ? "fiche activit\xE9" : "fiche t\xE2che";
    super(
      `La ${eventName} #${eventId} n'a pas \xE9t\xE9 pass\xE9e en demande de relecture. Seules des ${identifier}s refus\xE9es ou en brouillon le peuvent`
    );
  }
};
var ShouldAssignDrive = class extends FestivalEventError {
  static {
    __name(this, "ShouldAssignDrive");
  }
  constructor(identifier = FA) {
    super(
      `Il faut attribuer des lieux de retrait aux demandes de matos avant de valider la ${identifier}`
    );
  }
};
var AlreadyApproved = class extends FestivalEventError {
  static {
    __name(this, "AlreadyApproved");
  }
  constructor(eventId, team, identifier) {
    const message = `La ${identifier} #${eventId} est valid\xE9e par l'\xE9quipe ${team}`;
    super(message);
  }
};
var AlreadyApprovedBy = class extends FestivalEventError {
  static {
    __name(this, "AlreadyApprovedBy");
  }
  constructor(reviewers, identifier) {
    const plural = reviewers.length > 1;
    const noun = plural ? "les \xE9quipes" : "l'\xE9quipe";
    const reviewerListing = reviewers.join(" et ");
    super(
      `La ${identifier} a d\xE9j\xE0 \xE9t\xE9 valid\xE9e par ${noun} ${reviewerListing}.`
    );
  }
};

// src/festival-activity/preparation/prepare-in-review-festival-activity.ts
var IsNotPublicActivity = class extends FestivalActivityError {
  static {
    __name(this, "IsNotPublicActivity");
  }
  constructor(missingParts) {
    const baseError = "Il n'est pas possible de rendre publique cette FA";
    super([baseError, ...missingParts].join("\n"));
  }
};
var NeedAtLeastOneTimeWindow = class extends FestivalActivityError {
  static {
    __name(this, "NeedAtLeastOneTimeWindow");
  }
  constructor() {
    super("Il faut garder au moins un cr\xE9neau.");
  }
};
var General = class _General {
  constructor(general) {
    this.general = general;
  }
  static {
    __name(this, "General");
  }
  static init(general) {
    return new _General(general);
  }
  isAlreadyValidatedBy(reviewer, reviews) {
    switch (reviewer) {
      case communication:
        return reviews.communication === APPROVED;
      case humain:
        return reviews.humain === APPROVED;
    }
  }
  update(form) {
    const name = form.name ?? this.general.name;
    const description = form.description ?? this.general.description;
    const categories = form.categories ?? this.general.categories;
    const toPublish = form.toPublish ?? this.general.toPublish;
    const isFlagship = form.isFlagship ?? this.general.isFlagship;
    const photoLink = form.photoLink ?? this.general.photoLink;
    const { timeWindows } = this.general;
    if (!toPublish) {
      return {
        ...this.general,
        name,
        description,
        toPublish: false,
        categories,
        isFlagship: false,
        photoLink: null
      };
    }
    if (!hasAtLeastOneItem(categories) || !this.hasSetPhotoLink(photoLink) || !hasAtLeastOneItem(timeWindows)) {
      const missingPublicParts = [
        {
          verify: hasAtLeastOneItem(categories),
          message: "Il faut au moins une cat\xE9gorie."
        },
        {
          verify: this.hasSetPhotoLink(photoLink),
          message: "Il faut d\xE9finir un lien pour la photo."
        },
        {
          verify: hasAtLeastOneItem,
          message: "Il faut au moins un cr\xE9neau."
        }
      ].filter(({ verify }) => !verify).map(({ message }) => `- ${message}`);
      throw new IsNotPublicActivity(missingPublicParts);
    }
    return {
      name,
      description,
      toPublish,
      categories,
      isFlagship,
      photoLink,
      timeWindows
    };
  }
  hasSetPhotoLink(photoLink) {
    return photoLink !== null;
  }
};
var PrepareInReviewFestivalActivity = class _PrepareInReviewFestivalActivity {
  constructor(activity) {
    this.activity = activity;
  }
  static {
    __name(this, "PrepareInReviewFestivalActivity");
  }
  static build(activity) {
    return new _PrepareInReviewFestivalActivity(activity);
  }
  updateGeneral(form) {
    this.checkIfGeneralAlreadyApproved();
    const general = General.init(this.activity.general).update(form);
    const isSwitchingPrivacy = this.activity.general.toPublish !== general.toPublish;
    if (!isSwitchingPrivacy) return { ...this.activity, general };
    const reviews = {
      ...this.activity.reviews,
      communication: general.toPublish ? REVIEWING : NOT_ASKING_TO_REVIEW
    };
    if (isValidatedReviews(reviews)) {
      return { ...this.activity, general, reviews, status: VALIDATED3 };
    }
    if (isRefusedReviews(reviews)) {
      return { ...this.activity, general, reviews, status: REFUSED3 };
    }
    return { ...this.activity, general, reviews, status: IN_REVIEW3 };
  }
  addGeneralTimeWindow(period) {
    this.checkIfGeneralAlreadyApproved(humain);
    const timeWindows = TimeWindows.build(
      this.activity.general.timeWindows
    ).add(period).entries;
    const general = { ...this.activity.general, timeWindows };
    return { ...this.activity, general };
  }
  checkIfGeneralAlreadyApproved(askingReviewer) {
    const { reviews, general: generalData } = this.activity;
    const defaultReviewer = generalData.toPublish ? communication : humain;
    const reviewer = askingReviewer ?? defaultReviewer;
    const general = General.init(generalData);
    if (general.isAlreadyValidatedBy(reviewer, reviews)) {
      throw new AlreadyApprovedBy([reviewer], "FA");
    }
  }
  removeGeneralTimeWindow(id) {
    this.checkIfGeneralAlreadyApproved(humain);
    const currentGeneral = this.activity.general;
    const timeWindowsAggregate = TimeWindows.build(currentGeneral.timeWindows);
    const timeWindows = timeWindowsAggregate.remove(id).entries;
    if (isPrivate(currentGeneral)) {
      const general2 = { ...currentGeneral, timeWindows };
      return { ...this.activity, general: general2 };
    }
    if (!hasAtLeastOneItem(timeWindows)) throw new NeedAtLeastOneTimeWindow();
    const general = { ...currentGeneral, timeWindows };
    return { ...this.activity, general };
  }
  updateInCharge(form) {
    this.checkIfInChargeAlreadyApproved();
    const adherent = form.adherent ?? this.activity.inCharge.adherent;
    const team = form.team ?? this.activity.inCharge.team;
    const inCharge = { ...this.activity.inCharge, adherent, team };
    return { ...this.activity, inCharge };
  }
  checkIfInChargeAlreadyApproved() {
    const isValidated2 = this.activity.reviews.humain === APPROVED;
    if (isValidated2) {
      throw new AlreadyApprovedBy([humain], "FA");
    }
  }
  addContractor(contractor) {
    this.checkIfInChargeAlreadyApproved();
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).add(contractor).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  updateContractor(contractor) {
    this.checkIfInChargeAlreadyApproved();
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).update(contractor).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  removeContractor(id) {
    this.checkIfInChargeAlreadyApproved();
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).remove(id).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  updateSigna({ location }) {
    this.checkIfSignaAlreadyApproved();
    if (location === null) throw new LocationIsRequired();
    const signa2 = { ...this.activity.signa, location };
    return { ...this.activity, signa: signa2 };
  }
  checkIfSignaAlreadyApproved() {
    const isValidated2 = this.activity.reviews.signa === APPROVED;
    if (isValidated2) {
      throw new AlreadyApprovedBy([signa], "FA");
    }
  }
  addSignage(signage) {
    this.checkIfSignaAlreadyApproved();
    const signages = Signages.build(this.activity.signa.signages).add(
      signage
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  updateSignage(signage) {
    this.checkIfSignaAlreadyApproved();
    const signages = Signages.build(this.activity.signa.signages).update(
      signage
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  removeSignage(id) {
    this.checkIfSignaAlreadyApproved();
    const signages = Signages.build(this.activity.signa.signages).remove(
      id
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  linkSignageToCatalogItem(link) {
    const signages = Signages.build(
      this.activity.signa.signages
    ).linkCatalogItem(link).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  updateSecurity(form) {
    this.checkIfSecurityAlreadyApproved();
    const specialNeed = form.specialNeed === void 0 ? this.activity.security.specialNeed : form.specialNeed;
    const freePass = form.freePass ?? this.activity.security.freePass;
    if (freePass < 0) throw new FreePassMustBePositive();
    const security = { ...this.activity.inCharge, specialNeed, freePass };
    return { ...this.activity, security };
  }
  checkIfSecurityAlreadyApproved() {
    const isValidated2 = this.activity.reviews.secu === APPROVED;
    if (isValidated2) {
      throw new AlreadyApprovedBy([secu], "FA");
    }
  }
  updateSupply(form) {
    this.checkIfSupplyAlreadyApproved();
    const supply = { ...this.activity.supply, ...form };
    return { ...this.activity, supply };
  }
  checkIfSupplyAlreadyApproved() {
    const isValidated2 = this.activity.reviews.elec === APPROVED;
    if (isValidated2) {
      throw new AlreadyApprovedBy([elec], "FA");
    }
  }
  addElectricitySupply(electricitySupply) {
    this.checkIfSupplyAlreadyApproved();
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).add(electricitySupply).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  updateElectricitySupply(electricitySupply) {
    this.checkIfSupplyAlreadyApproved();
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).update(electricitySupply).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  removeElectricitySupply(id) {
    this.checkIfSupplyAlreadyApproved();
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).remove(id).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  initInquiry({ request, timeWindow }) {
    if (Inquiries.alreadyInitialized(this.activity.inquiry)) {
      throw new AlreadyInitialized();
    }
    this.checkIfInquiryAlreadyApprovedBy(request.owner);
    const inquiry = Inquiries.init().addRequest(request).addTimeWindow(timeWindow).inquiry;
    return { ...this.activity, inquiry };
  }
  clearInquiry() {
    this.checkIfHasImpactOnApprovedRequests();
    const inquiry = Inquiries.init().inquiry;
    return { ...this.activity, inquiry };
  }
  addInquiryTimeWindow(period) {
    this.checkIfHasImpactOnApprovedRequests();
    this.checkIfAlreadyInitialized();
    const inquiry = Inquiries.build(this.activity.inquiry).addTimeWindow(
      period
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  checkIfHasImpactOnApprovedRequests() {
    const elec2 = [this.activity.inquiry.electricity, ELEC];
    const gear = [this.activity.inquiry.gears, MATOS];
    const barrier = [this.activity.inquiry.barriers, BARRIERES];
    const hasImpact = [elec2, gear, barrier].some(([request, owner]) => {
      const hasRequest = request.length > 0;
      const { approved } = this.isInquiryApprovedBy(owner);
      return hasRequest && approved;
    });
    const allApproved = this.activity.reviews.barrieres === APPROVED && this.activity.reviews.elec === APPROVED && this.activity.reviews.matos === APPROVED;
    if (hasImpact || allApproved) {
      return this.checkIfInquiryAlreadyApprovedBy();
    }
  }
  checkIfInquiryAlreadyApprovedBy(owner) {
    const { approved, teams } = this.isInquiryApprovedBy(owner);
    if (approved) {
      throw new AlreadyApprovedBy(teams, "FA");
    }
  }
  isInquiryApprovedBy(owner) {
    const hasElecApproved = {
      approved: this.activity.reviews.elec === APPROVED,
      teams: [elec]
    };
    const hasMatosApproved = {
      approved: this.activity.reviews.matos === APPROVED,
      teams: [matos]
    };
    const hasBarrierersApproved = {
      approved: this.activity.reviews.barrieres === APPROVED,
      teams: [barrieres]
    };
    const reviews = [hasBarrierersApproved, hasElecApproved, hasMatosApproved];
    switch (owner) {
      case void 0:
        return reviews.reduce(
          (acc, approval) => {
            return {
              approved: acc.approved || approval.approved,
              teams: approval.approved ? [...acc.teams, ...approval.teams] : acc.teams
            };
          },
          { approved: false, teams: [] }
        );
      case matos:
        return hasMatosApproved;
      case elec:
        return hasElecApproved;
      case barrieres:
        return hasBarrierersApproved;
    }
  }
  removeInquiryTimeWindow(id) {
    this.checkIfHasImpactOnApprovedRequests();
    const inquiry = Inquiries.build(this.activity.inquiry).removeTimeWindow(
      id
    ).inquiry;
    if (this.hasNoTimeWindowRemaining(inquiry)) {
      throw new CantRemoveLastTimeWindow();
    }
    return { ...this.activity, inquiry };
  }
  hasNoTimeWindowRemaining(inquiry) {
    return inquiry.timeWindows.length === 0;
  }
  addInquiry(request) {
    this.checkIfInquiryAlreadyApprovedBy(request.owner);
    this.checkIfAlreadyInitialized();
    const inquiry = Inquiries.build(this.activity.inquiry).addRequest(
      request
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  checkIfAlreadyInitialized() {
    if (!Inquiries.alreadyInitialized(this.activity.inquiry)) {
      throw new NotYetInitialized();
    }
  }
  removeInquiry(request) {
    this.checkIfInquiryAlreadyApprovedBy(request.owner);
    const inquiry = Inquiries.build(this.activity.inquiry).removeRequest(
      request.slug
    ).inquiry;
    if (this.hasNoRequestRemaining(inquiry)) {
      throw new CantRemoveLastRequest();
    }
    return { ...this.activity, inquiry };
  }
  hasNoRequestRemaining(inquiry) {
    const requests = [
      ...inquiry.gears,
      ...inquiry.barriers,
      ...inquiry.electricity
    ];
    return requests.length === 0;
  }
  assignInquiryToDrive(link) {
    const inquiry = Inquiries.build(this.activity.inquiry).assignDrive(
      link
    ).inquiry;
    return { ...this.activity, inquiry };
  }
};

// src/common/inquiry-request.error.ts
var AssignDriveInDraft = class extends FestivalEventError {
  static {
    __name(this, "AssignDriveInDraft");
  }
  constructor(identifier) {
    super(
      `Il n'est pas possible d'attribuer un lieu \xE0 une demande de matos dans une ${identifier} en brouillon`
    );
  }
};

// src/festival-activity/preparation/prepare-draft-festival-activity.ts
var AssignCatalogItemInDraftActivity = class extends FestivalActivityError {
  static {
    __name(this, "AssignCatalogItemInDraftActivity");
  }
  constructor() {
    super(
      "Il n'est pas possible d'attribuer une signal\xE9tique du catalogue \xE0 une demande de signal\xE9tique dans une FA en brouillon"
    );
  }
};
var PrepareDraftFestivalActivity = class _PrepareDraftFestivalActivity {
  constructor(activity) {
    this.activity = activity;
  }
  static {
    __name(this, "PrepareDraftFestivalActivity");
  }
  static build(activity) {
    return new _PrepareDraftFestivalActivity(activity);
  }
  updateGeneral(form) {
    const privateFestivalActivity = {
      toPublish: false,
      photoLink: null,
      isFlagship: false
    };
    const cleanedUpdate = form.toPublish === false ? { ...form, ...privateFestivalActivity } : form;
    const general = { ...this.activity.general, ...cleanedUpdate };
    return { ...this.activity, general };
  }
  addGeneralTimeWindow(period) {
    const timeWindows = TimeWindows.build(
      this.activity.general.timeWindows
    ).add(period).entries;
    const general = { ...this.activity.general, timeWindows };
    return { ...this.activity, general };
  }
  removeGeneralTimeWindow(id) {
    const timeWindows = TimeWindows.build(
      this.activity.general.timeWindows
    ).remove(id).entries;
    const general = { ...this.activity.general, timeWindows };
    return { ...this.activity, general };
  }
  updateInCharge(form) {
    const inCharge = { ...this.activity.inCharge, ...form };
    return { ...this.activity, inCharge };
  }
  addContractor(contractor) {
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).add(contractor).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  updateContractor(contractor) {
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).update(contractor).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  removeContractor(id) {
    const contractors = Contractors.build(
      this.activity.inCharge.contractors
    ).remove(id).entries;
    const inCharge = { ...this.activity.inCharge, contractors };
    return { ...this.activity, inCharge };
  }
  updateSigna(form) {
    const signa2 = { ...this.activity.signa, ...form };
    return { ...this.activity, signa: signa2 };
  }
  addSignage(signage) {
    const signages = Signages.build(this.activity.signa.signages).add(
      signage
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  updateSignage(signage) {
    const signages = Signages.build(this.activity.signa.signages).update(
      signage
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  removeSignage(id) {
    const signages = Signages.build(this.activity.signa.signages).remove(
      id
    ).entries;
    const signa2 = { ...this.activity.signa, signages };
    return { ...this.activity, signa: signa2 };
  }
  linkSignageToCatalogItem() {
    throw new AssignCatalogItemInDraftActivity();
  }
  updateSecurity(form) {
    const security = { ...this.activity.security, ...form };
    if (security.freePass < 0) throw new FreePassMustBePositive();
    return { ...this.activity, security };
  }
  updateSupply(form) {
    const supply = { ...this.activity.supply, ...form };
    return { ...this.activity, supply };
  }
  addElectricitySupply(electricitySupply) {
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).add(electricitySupply).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  updateElectricitySupply(electricitySupply) {
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).update(electricitySupply).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  removeElectricitySupply(id) {
    const electricity = ElectricitySupplies.build(
      this.activity.supply.electricity
    ).remove(id).entries;
    const supply = { ...this.activity.supply, electricity };
    return { ...this.activity, supply };
  }
  initInquiry({ timeWindow, request }) {
    if (Inquiries.alreadyInitialized(this.activity.inquiry)) {
      throw new AlreadyInitialized();
    }
    const inquiry = Inquiries.init().addRequest(request).addTimeWindow(timeWindow).inquiry;
    return { ...this.activity, inquiry };
  }
  clearInquiry() {
    const inquiry = Inquiries.init().inquiry;
    return { ...this.activity, inquiry };
  }
  addInquiryTimeWindow(period) {
    const inquiry = Inquiries.build(this.activity.inquiry).addTimeWindow(
      period
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  removeInquiryTimeWindow(id) {
    const inquiry = Inquiries.build(this.activity.inquiry).removeTimeWindow(
      id
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  addInquiry(form) {
    const inquiry = Inquiries.build(this.activity.inquiry).addRequest(
      form
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  removeInquiry(form) {
    const inquiry = Inquiries.build(this.activity.inquiry).removeRequest(
      form.slug
    ).inquiry;
    return { ...this.activity, inquiry };
  }
  assignInquiryToDrive() {
    throw new AssignDriveInDraft("FA");
  }
};

// src/festival-activity/preparation/prepare-festival-activity.ts
var PrepareFestivalActivity = class {
  constructor(festivalActivities) {
    this.festivalActivities = festivalActivities;
  }
  static {
    __name(this, "PrepareFestivalActivity");
  }
  findAll() {
    return this.festivalActivities.findAll();
  }
  findById(id) {
    return this.festivalActivities.findById(id);
  }
  getPrepareHelper(existingFA) {
    return isDraft(existingFA) ? PrepareDraftFestivalActivity.build(existingFA) : PrepareInReviewFestivalActivity.build(existingFA);
  }
  async updateGeneralSection(id, general) {
    const existingFA = await this.findActivityIfExists(id);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateGeneral(general);
    return this.festivalActivities.save(updatedFA);
  }
  async addTimeWindowInGeneral(faId, period) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addGeneralTimeWindow(period);
    return this.festivalActivities.save(updatedFA);
  }
  async removeTimeWindowFromGeneral(faId, timeWindowId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeGeneralTimeWindow(timeWindowId);
    return this.festivalActivities.save(updatedFA);
  }
  async updateInChargeSection(id, inCharge) {
    const existingFA = await this.findActivityIfExists(id);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateInCharge(inCharge);
    return this.festivalActivities.save(updatedFA);
  }
  async addContractor(faId, contractor) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addContractor(contractor);
    return this.festivalActivities.save(updatedFA);
  }
  async updateContractor(faId, contractor) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateContractor(contractor);
    return this.festivalActivities.save(updatedFA);
  }
  async removeContractor(faId, contractorId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeContractor(contractorId);
    return this.festivalActivities.save(updatedFA);
  }
  async updateSignaSection(id, signa2) {
    const existingFA = await this.findActivityIfExists(id);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateSigna(signa2);
    return this.festivalActivities.save(updatedFA);
  }
  async addSignage(faId, signage) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addSignage(signage);
    return this.festivalActivities.save(updatedFA);
  }
  async updateSignage(faId, signage) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateSignage(signage);
    return this.festivalActivities.save(updatedFA);
  }
  async removeSignage(faId, signageId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeSignage(signageId);
    return this.festivalActivities.save(updatedFA);
  }
  async updateSecuritySection(id, security) {
    const existingFA = await this.findActivityIfExists(id);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateSecurity(security);
    return this.festivalActivities.save(updatedFA);
  }
  async updateSupplySection(id, supply) {
    const existingFA = await this.findActivityIfExists(id);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateSupply(supply);
    return this.festivalActivities.save(updatedFA);
  }
  async addElectricitySupply(faId, electricitySupply) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addElectricitySupply(electricitySupply);
    return this.festivalActivities.save(updatedFA);
  }
  async updateElectricitySupply(faId, electricitySupply) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.updateElectricitySupply(electricitySupply);
    return this.festivalActivities.save(updatedFA);
  }
  async removeElectricitySupply(faId, electricitySupplyId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeElectricitySupply(electricitySupplyId);
    return this.festivalActivities.save(updatedFA);
  }
  async initInquiry(faId, inquiryInitializer) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.initInquiry(inquiryInitializer);
    return this.festivalActivities.save(updatedFA);
  }
  async clearInquiry(faId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.clearInquiry();
    return this.festivalActivities.save(updatedFA);
  }
  async addTimeWindowInInquiry(faId, period) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addInquiryTimeWindow(period);
    return this.festivalActivities.save(updatedFA);
  }
  async removeTimeWindowFromInquiry(faId, timeWindowId) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeInquiryTimeWindow(timeWindowId);
    return this.festivalActivities.save(updatedFA);
  }
  async addInquiryRequest(faId, inquiry) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.addInquiry(inquiry);
    return this.festivalActivities.save(updatedFA);
  }
  async removeInquiryRequest(faId, inquiry) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.removeInquiry(inquiry);
    return this.festivalActivities.save(updatedFA);
  }
  async findActivityIfExists(id) {
    const existingFA = await this.festivalActivities.findById(id);
    if (!existingFA) throw new FestivalActivityNotFound(id);
    return existingFA;
  }
  async assignInquiryToDrive(faId, link) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.assignInquiryToDrive(link);
    return this.festivalActivities.save(updatedFA);
  }
  async linkSignageToCatalogItem(faId, link) {
    const existingFA = await this.findActivityIfExists(faId);
    const prepare = this.getPrepareHelper(existingFA);
    const updatedFA = prepare.linkSignageToCatalogItem(link);
    return this.festivalActivities.save(updatedFA);
  }
  async publishFeedback(faId, { author, content }) {
    const existingFA = await this.findActivityIfExists(faId);
    const feedback = { author, content, publishedAt: /* @__PURE__ */ new Date() };
    const feedbacks = [...existingFA.feedbacks, feedback];
    const updatedFA = { ...existingFA, feedbacks };
    return this.festivalActivities.save(updatedFA);
  }
};

// src/festival-activity/ask-for-review/festival-activities.inmemory.ts
import { updateItemToList as updateItemToList5 } from "@overbookd/list";
var InMemoryAskForReviewFestivalActivityRepository = class {
  constructor(festivalActivities) {
    this.festivalActivities = festivalActivities;
  }
  static {
    __name(this, "InMemoryAskForReviewFestivalActivityRepository");
  }
  findById(id) {
    const festivalActivity = this.festivalActivities.find(
      (festivalActivity2) => festivalActivity2.id === id
    );
    return Promise.resolve(festivalActivity ?? null);
  }
  save(festivalActivity) {
    const festivalActivityIndex = this.festivalActivities.findIndex(
      (festivalActivityToUpdate) => festivalActivityToUpdate.id === festivalActivity.id
    );
    if (festivalActivityIndex === -1) {
      throw new FestivalActivityNotFound(festivalActivity.id);
    }
    this.festivalActivities = updateItemToList5(
      this.festivalActivities,
      festivalActivityIndex,
      festivalActivity
    );
    return Promise.resolve(festivalActivity);
  }
};

// src/festival-activity/ask-for-review/in-review-festival-activity.ts
import { IN_REVIEW as IN_REVIEW4 } from "@overbookd/festival-event-constants";

// src/festival-activity/ask-for-review/specifications/general-section-specification.ts
var REQUIRED_DESCRIPTION = "Une description est n\xE9cessaire";
var REQUIRED_PHOTO_ON_PUBLIC_ACTIVITY = "Une photo est n\xE9cessaire pour les animations publi\xE9es";
var REQUIRED_CATEGORIES_ON_PUBLIC_ACTIVITY = "Au moins une cat\xE9gorie est n\xE9cessaire pour les animations publi\xE9es";
var REQUIRED_TIMEWINDOWS_ON_PUBLIC_ACTIVITY = "Au moins un cr\xE9neau horaire est n\xE9cessaire pour les animations publi\xE9es";
var ActivityGeneralSpecification = class {
  static {
    __name(this, "ActivityGeneralSpecification");
  }
  static errors(section) {
    return this.hasDescriptionSet(section) ? [] : [REQUIRED_DESCRIPTION];
  }
  static hasDescriptionSet(section) {
    return section.description !== null;
  }
};
var PublicActivityGeneralSpecification = class {
  static {
    __name(this, "PublicActivityGeneralSpecification");
  }
  static errors(section) {
    return [
      ...ActivityGeneralSpecification.errors(section),
      ...this.photoLinkError(section),
      ...this.categoriesError(section),
      ...this.timeWindowsError(section)
    ];
  }
  static timeWindowsError(section) {
    if (this.hasAtLeastOneTimeWindow(section)) return [];
    return [REQUIRED_TIMEWINDOWS_ON_PUBLIC_ACTIVITY];
  }
  static categoriesError(section) {
    if (this.hasAtLeastOneCategory(section)) return [];
    return [REQUIRED_CATEGORIES_ON_PUBLIC_ACTIVITY];
  }
  static photoLinkError(section) {
    if (this.hasPhotoLinkSet(section)) return [];
    return [REQUIRED_PHOTO_ON_PUBLIC_ACTIVITY];
  }
  static hasAtLeastOneTimeWindow(section) {
    return section.timeWindows.length > 0;
  }
  static hasAtLeastOneCategory(section) {
    return section.categories.length > 0;
  }
  static hasPhotoLinkSet(section) {
    return section.photoLink !== null;
  }
};

// src/festival-activity/ask-for-review/specifications/in-charge-section-specification.ts
var REQUIRED_TEAM = "Une \xE9quipe responsable est n\xE9cessaire";
var ActivityInChargeSpecification = class {
  static {
    __name(this, "ActivityInChargeSpecification");
  }
  static errors(section) {
    return this.hasTeamSet(section) ? [] : [REQUIRED_TEAM];
  }
  static hasTeamSet(section) {
    return section.team !== null;
  }
};

// src/festival-activity/ask-for-review/specifications/signa-section-specification.ts
var REQUIRED_LOCATION = "Le lieu est n\xE9cessaire";
var ActivitySignaSpecification = class {
  static {
    __name(this, "ActivitySignaSpecification");
  }
  static errors(section) {
    if (this.hasLocationSet(section)) {
      return [];
    }
    return [REQUIRED_LOCATION];
  }
  static hasLocationSet(section) {
    return section.location !== null;
  }
};

// src/festival-activity/ask-for-review/specifications/inquiry-section-specification.ts
var REQUIRED_INQUIRY_WITH_TIMEWINDOWS = "Au moins une demande de matos est n\xE9cessaire pour un cr\xE9neau matos";
var REQUIRED_TIMEWINDOWS_WITH_INQUIRY = "Au moins un cr\xE9neau matos est n\xE9cessaire pour une demande matos";
function isWithTimeWindows(section) {
  return section.timeWindows.length > 0;
}
__name(isWithTimeWindows, "isWithTimeWindows");
function isWithRequest(section) {
  const { barriers, electricity, gears } = section;
  const requests = barriers.length + electricity.length + gears.length;
  return requests > 0;
}
__name(isWithRequest, "isWithRequest");
var ActivityInquiryWithTimeWindowsSpecification = class {
  static {
    __name(this, "ActivityInquiryWithTimeWindowsSpecification");
  }
  static errors(section) {
    if (isWithRequest(section)) {
      return [];
    }
    return [REQUIRED_INQUIRY_WITH_TIMEWINDOWS];
  }
};
var ActivityInquiryWithRequestSpecification = class {
  static {
    __name(this, "ActivityInquiryWithRequestSpecification");
  }
  static errors(section) {
    if (isWithTimeWindows(section)) {
      return [];
    }
    return [REQUIRED_TIMEWINDOWS_WITH_INQUIRY];
  }
};
var ActivityInquirySpecification = class {
  static {
    __name(this, "ActivityInquirySpecification");
  }
  static errors(section) {
    if (isWithTimeWindows(section)) {
      return ActivityInquiryWithTimeWindowsSpecification.errors(section);
    }
    if (isWithRequest(section)) {
      return ActivityInquiryWithRequestSpecification.errors(section);
    }
    return [];
  }
};

// src/common/ready-for-review.error.ts
var CANT_MOVE_TO_IN_REVIEW_ERROR_MESSAGE = "n'a pas pu \xEAtre pass\xE9e en relecture";
var ReadyForReviewError = class extends FestivalEventError {
  static {
    __name(this, "ReadyForReviewError");
  }
  constructor(errors, identifier = "FA") {
    const cantMoveToInReview = `La ${identifier} ${CANT_MOVE_TO_IN_REVIEW_ERROR_MESSAGE}`;
    const errorList = errors.map((error) => `- ${error}`).join("\n");
    const message = `${cantMoveToInReview}
${errorList}`;
    super(message);
  }
};

// src/festival-activity/ask-for-review/in-review-festival-activity.ts
var PRIVATE_ACTIVITY_REVIEWS = {
  communication: NOT_ASKING_TO_REVIEW,
  humain: REVIEWING,
  signa: REVIEWING,
  secu: REVIEWING,
  matos: REVIEWING,
  elec: REVIEWING,
  barrieres: REVIEWING
};
var PUBLIC_ACTIVITY_REVIEWS = {
  communication: REVIEWING,
  humain: REVIEWING,
  signa: REVIEWING,
  secu: REVIEWING,
  matos: REVIEWING,
  elec: REVIEWING,
  barrieres: REVIEWING
};
var ReviewableSpecification = class {
  static {
    __name(this, "ReviewableSpecification");
  }
  static isSatisfiedBy(festivalActivity) {
    return this.errors(festivalActivity).length === 0;
  }
  static generateError(festivalActivity) {
    return new ReadyForReviewError(this.errors(festivalActivity));
  }
  static errors(festivalActivity) {
    const general = isPublicActivity(festivalActivity.general) ? PublicActivityGeneralSpecification.errors(festivalActivity.general) : ActivityGeneralSpecification.errors(festivalActivity.general);
    const inCharge = ActivityInChargeSpecification.errors(
      festivalActivity.inCharge
    );
    const signa2 = ActivitySignaSpecification.errors(festivalActivity.signa);
    const inquiry = ActivityInquirySpecification.errors(
      festivalActivity.inquiry
    );
    return [...general, ...inCharge, ...signa2, ...inquiry];
  }
};
var COMMON_REVIEWERS = [
  humain,
  signa,
  secu,
  matos,
  elec,
  barrieres
];
var PUBLIC_REVIEWERS = [...COMMON_REVIEWERS, communication];
var InReviewFestivalActivity = class _InReviewFestivalActivity {
  constructor(id, general, inCharge, signa2, security, supply, inquiry, reviews, feedbacks, history, tasks, previousReviews) {
    this.id = id;
    this.general = general;
    this.inCharge = inCharge;
    this.signa = signa2;
    this.security = security;
    this.supply = supply;
    this.inquiry = inquiry;
    this.reviews = reviews;
    this.feedbacks = feedbacks;
    this.history = history;
    this.tasks = tasks;
    this.previousReviews = previousReviews;
  }
  static {
    __name(this, "InReviewFestivalActivity");
  }
  get status() {
    return IN_REVIEW4;
  }
  static init(activity, instigator) {
    if (!ReviewableSpecification.isSatisfiedBy(activity)) {
      throw ReviewableSpecification.generateError(activity);
    }
    const isPublic = activity.general.toPublish;
    const reviews = isPublic ? PUBLIC_ACTIVITY_REVIEWS : PRIVATE_ACTIVITY_REVIEWS;
    const history = [
      ...activity.history,
      FestivalActivityKeyEvents.readyToReview(instigator)
    ];
    return new _InReviewFestivalActivity(
      activity.id,
      activity.general,
      activity.inCharge,
      activity.signa,
      activity.security,
      activity.supply,
      activity.inquiry,
      reviews,
      activity.feedbacks,
      history,
      activity.tasks
    );
  }
  static build(activity, instigator) {
    const reviews = this.swapRefusedToReviewing(activity.reviews);
    const history = [
      ...activity.history,
      FestivalActivityKeyEvents.readyToReview(instigator)
    ];
    return new _InReviewFestivalActivity(
      activity.id,
      activity.general,
      activity.inCharge,
      activity.signa,
      activity.security,
      activity.supply,
      activity.inquiry,
      reviews,
      activity.feedbacks,
      history,
      activity.tasks,
      activity.reviews
    );
  }
  static swapRefusedToReviewing(reviews) {
    return {
      communication: reviews.communication !== REJECTED ? reviews.communication : REVIEWING,
      humain: reviews.humain !== REJECTED ? reviews.humain : REVIEWING,
      signa: reviews.signa !== REJECTED ? reviews.signa : REVIEWING,
      secu: reviews.secu !== REJECTED ? reviews.secu : REVIEWING,
      matos: reviews.matos !== REJECTED ? reviews.matos : REVIEWING,
      elec: reviews.elec !== REJECTED ? reviews.elec : REVIEWING,
      barrieres: reviews.barrieres !== REJECTED ? reviews.barrieres : REVIEWING
    };
  }
  get isPublic() {
    return this.general.toPublish;
  }
  get readyForReview() {
    return {
      id: this.id,
      name: this.general.name,
      reviewers: this.reviewersToNotify
    };
  }
  get reviewersToNotify() {
    if (!this.previousReviews) {
      return this.isPublic ? PUBLIC_REVIEWERS : COMMON_REVIEWERS;
    }
    return PUBLIC_REVIEWERS.filter((reviewer) => this.hasRejected(reviewer));
  }
  hasRejected(reviewer) {
    switch (reviewer) {
      case humain:
        return this.previousReviews?.humain === REJECTED;
      case signa:
        return this.previousReviews?.signa === REJECTED;
      case secu:
        return this.previousReviews?.secu === REJECTED;
      case matos:
        return this.previousReviews?.matos === REJECTED;
      case elec:
        return this.previousReviews?.elec === REJECTED;
      case barrieres:
        return this.previousReviews?.barrieres === REJECTED;
      case communication:
        return this.previousReviews?.communication === REJECTED;
    }
  }
};
function isPublicActivity(general) {
  return general.toPublish;
}
__name(isPublicActivity, "isPublicActivity");

// src/festival-activity/ask-for-review/ask-for-review.ts
var AskForReview = class {
  constructor(festivalActivities, notifications) {
    this.festivalActivities = festivalActivities;
    this.notifications = notifications;
  }
  static {
    __name(this, "AskForReview");
  }
  async from(activityId, instigator) {
    const festivalActivity = await this.festivalActivities.findById(activityId);
    if (!festivalActivity) throw new FestivalActivityNotFound(activityId);
    if (isDraft(festivalActivity)) {
      return this.fromDraft(festivalActivity, instigator);
    }
    if (isRefused(festivalActivity)) {
      return this.fromRefused(festivalActivity, instigator);
    }
    throw new CantAskForReview(activityId);
  }
  async fromDraft(activity, instigator) {
    const inReview = InReviewFestivalActivity.init(activity, instigator);
    this.notifications.add(inReview.readyForReview);
    return this.festivalActivities.save(inReview);
  }
  async fromRefused(activity, instigator) {
    const inReview = InReviewFestivalActivity.build(activity, instigator);
    this.notifications.add(inReview.readyForReview);
    return this.festivalActivities.save(inReview);
  }
};
function isReviewer(team) {
  return [barrieres, communication, elec, humain, matos, secu, signa].includes(
    team
  );
}
__name(isReviewer, "isReviewer");

// src/festival-activity/reviewing/reviewing.ts
import {
  IN_REVIEW as IN_REVIEW5,
  REFUSED as REFUSED4,
  VALIDATED as VALIDATED4
} from "@overbookd/festival-event-constants";

// src/festival-activity/reviewing/reviewing.error.ts
var InDraft = class extends FestivalActivityError {
  static {
    __name(this, "InDraft");
  }
  constructor(festivalActivityId) {
    const message = `La FA #${festivalActivityId} est encore en brouillon`;
    super(message);
  }
};
var AlreadyRejected = class extends FestivalActivityError {
  static {
    __name(this, "AlreadyRejected");
  }
  constructor(festivalActivityId, team) {
    const message = `La FA #${festivalActivityId} est refus\xE9e par l'\xE9quipe ${team}`;
    super(message);
  }
};
var ShouldLinkCatalogItem = class extends FestivalActivityError {
  static {
    __name(this, "ShouldLinkCatalogItem");
  }
  constructor() {
    super(
      "Il faut attribuer des r\xE9f\xE9rences du catalogue aux demandes de signal\xE9tiques avant de valider la FA"
    );
  }
};

// src/festival-activity/reviewing/reviewing.ts
var Reviewing = class {
  constructor(festivalActivities) {
    this.festivalActivities = festivalActivities;
  }
  static {
    __name(this, "Reviewing");
  }
  async approve(faId, team, approver) {
    const festivalActivity = await this.festivalActivities.findById(faId);
    if (!festivalActivity) throw new FestivalActivityNotFound(faId);
    if (isDraft(festivalActivity)) throw new InDraft(faId);
    if (this.isAlreadyApprovedBy(festivalActivity, team)) {
      throw new AlreadyApproved(faId, team, "FA");
    }
    if (this.isNotAskingToReview(festivalActivity, team)) {
      throw new NotAskingToReview(faId, team, "FA");
    }
    if (isInquiryOwner(team)) {
      this.checkInquiryDriveAssignment(festivalActivity, team);
    }
    if (team === signa) {
      this.checkSignageCatalogItemLink(festivalActivity);
    }
    const reviews = { ...festivalActivity.reviews, [team]: APPROVED };
    const history = [
      ...festivalActivity.history,
      FestivalActivityKeyEvents.approved(approver)
    ];
    return this.saveFestivalActivity(festivalActivity, reviews, history);
  }
  async reject(faId, { team, rejector, reason }) {
    const festivalActivity = await this.festivalActivities.findById(faId);
    if (!festivalActivity) throw new FestivalActivityNotFound(faId);
    if (isDraft(festivalActivity)) throw new InDraft(faId);
    if (this.isNotAskingToReview(festivalActivity, team)) {
      throw new NotAskingToReview(faId, team, "FA");
    }
    if (this.isAlreadyRejectedBy(festivalActivity, team)) {
      throw new AlreadyRejected(faId, team);
    }
    const reviews = { ...festivalActivity.reviews, [team]: REJECTED };
    const history = [
      ...festivalActivity.history,
      FestivalActivityKeyEvents.rejected(rejector, reason)
    ];
    return this.festivalActivities.save({
      ...festivalActivity,
      status: REFUSED4,
      reviews,
      history
    });
  }
  async saveFestivalActivity(storedActivity, reviews, history) {
    if (isValidatedReviews(reviews)) {
      return this.festivalActivities.save({
        ...storedActivity,
        status: VALIDATED4,
        reviews,
        history
      });
    }
    if (isRefusedReviews(reviews)) {
      return this.festivalActivities.save({
        ...storedActivity,
        status: REFUSED4,
        reviews,
        history
      });
    }
    return this.festivalActivities.save({
      ...storedActivity,
      status: IN_REVIEW5,
      reviews,
      history
    });
  }
  checkInquiryDriveAssignment(festivalActivity, owner) {
    const requests = selectMyInquiryRequests(owner, festivalActivity);
    const areAllRequestsAssignedToDrive = requests.every(
      (request) => Object.hasOwn(request, "drive")
    );
    if (!areAllRequestsAssignedToDrive) {
      throw new ShouldAssignDrive();
    }
  }
  checkSignageCatalogItemLink(festivalActivity) {
    const signages = festivalActivity.signa.signages;
    const areAllSignagesLinkedToCatalogItem = signages.every(
      (signage) => isLinkedToCatalogItem(signage)
    );
    if (!areAllSignagesLinkedToCatalogItem) {
      throw new ShouldLinkCatalogItem();
    }
  }
  isAlreadyApprovedBy(festivalActivity, team) {
    const teamReview = getTeamReview(festivalActivity.reviews, team);
    return teamReview === APPROVED;
  }
  isAlreadyRejectedBy(festivalActivity, team) {
    const teamReview = getTeamReview(festivalActivity.reviews, team);
    return teamReview === REJECTED;
  }
  isNotAskingToReview(festivalActivity, team) {
    const teamReview = getTeamReview(festivalActivity.reviews, team);
    return teamReview === NOT_ASKING_TO_REVIEW;
  }
};
function getTeamReview(reviews, team) {
  switch (team) {
    case humain:
      return reviews.humain;
    case signa:
      return reviews.signa;
    case secu:
      return reviews.secu;
    case matos:
      return reviews.matos;
    case elec:
      return reviews.elec;
    case barrieres:
      return reviews.barrieres;
    case communication:
      return reviews.communication;
  }
}
__name(getTeamReview, "getTeamReview");
function selectMyInquiryRequests(owner, festivalActivity) {
  switch (owner) {
    case MATOS:
      return festivalActivity.inquiry.gears;
    case BARRIERES:
      return festivalActivity.inquiry.barriers;
    case ELEC:
      return festivalActivity.inquiry.electricity;
  }
}
__name(selectMyInquiryRequests, "selectMyInquiryRequests");
function isInquiryOwner(team) {
  return [MATOS, ELEC, BARRIERES].includes(team);
}
__name(isInquiryOwner, "isInquiryOwner");

// src/festival-task/create/create.ts
import { numberGenerator as numberGenerator3 } from "@overbookd/list";
import { DRAFT as DRAFT4 } from "@overbookd/festival-event-constants";

// src/festival-task/festival-task.event.ts
var FestivalTaskKeyEvents = class {
  static {
    __name(this, "FestivalTaskKeyEvents");
  }
  static created(by) {
    const at = this.computeAt();
    return { action: CREATED, by, at, description: "FT cr\xE9\xE9e" };
  }
  static resetReview(by, reason) {
    const at = this.computeAt();
    const description = `Pr\xE9c\xE9dentes approbations r\xE9initialis\xE9es par ${reason}`;
    return { action: RESET_REVIEW, by, at, description };
  }
  static readyToReview(by) {
    const at = this.computeAt();
    const description = "Demande de relecture de la FT";
    return { action: READY_TO_REVIEW, by, at, description };
  }
  static rejected(by, reason) {
    const at = this.computeAt();
    const description = `FT rejet\xE9e pour la raison suivante: ${reason}`;
    return { action: REJECTED, by, at, description };
  }
  static approved(by) {
    const at = this.computeAt();
    const description = "FT approuv\xE9e";
    return { action: APPROVED, by, at, description };
  }
  static assignmentStarted(by) {
    const at = this.computeAt();
    const description = "Affectation activ\xE9e pour la FT";
    return { action: ASSIGNMENT_STARTED, by, at, description };
  }
  static forceInstructions(by) {
    const at = this.computeAt();
    const description = "Mise \xE0 jour forc\xE9e des instructions";
    return { action: FORCED_UPDATE, by, at, description };
  }
  static computeAt() {
    const at = /* @__PURE__ */ new Date();
    at.setMilliseconds(0);
    return at;
  }
};

// src/festival-task/create/create.ts
var FT_420 = 420;
var CreateFestivalTask = class {
  constructor(festivalTasks, festivalTaskTranslator, startId = 1) {
    this.festivalTasks = festivalTasks;
    this.festivalTaskTranslator = festivalTaskTranslator;
    this.idGenerator = numberGenerator3(startId, [FT_420]);
  }
  static {
    __name(this, "CreateFestivalTask");
  }
  idGenerator;
  async apply({
    name,
    author,
    festivalActivity
  }) {
    const festivalTask = {
      id: this.generateId(),
      status: DRAFT4,
      festivalActivity,
      general: {
        name,
        administrator: author,
        team: null
      },
      instructions: {
        appointment: null,
        global: null,
        inCharge: {
          volunteers: [],
          instruction: null
        },
        contacts: []
      },
      history: [FestivalTaskKeyEvents.created(author)],
      feedbacks: [],
      mobilizations: [],
      inquiries: []
    };
    const created = await this.festivalTasks.add(festivalTask);
    const withConflicts = await this.festivalTaskTranslator.translate(created);
    if (!isDraft(withConflicts)) {
      throw new FestivalTaskError("Impossible de cr\xE9er en BROUILLON");
    }
    return withConflicts;
  }
  generateId() {
    return this.idGenerator.next().value;
  }
};

// src/festival-task/ask-for-review/in-review-festival-task.ts
import { IN_REVIEW as IN_REVIEW6 } from "@overbookd/festival-event-constants";

// src/festival-task/ask-for-review/in-review-specification.ts
import { hasAtLeastOneItem as hasAtLeastOneItem2, isEmpty } from "@overbookd/list";
var InReviewSpecification = class {
  constructor(task) {
    this.task = task;
  }
  static {
    __name(this, "InReviewSpecification");
  }
  static isSatisfiedBy(task) {
    return GeneralSpecification.isSatisfiedBy(task.general) && MobilizationsSpecification.isSatisfiedBy(task.mobilizations) && InstructionsSpecification.isSatisfiedBy(task.instructions);
  }
  static generateErrors(task) {
    return [
      ...GeneralSpecification.generateErrors(task.general),
      ...InstructionsSpecification.generateErrors(task.instructions),
      ...MobilizationsSpecification.generateErrors(task.mobilizations)
    ];
  }
};
var GeneralSpecification = class {
  static {
    __name(this, "GeneralSpecification");
  }
  static isSatisfiedBy(general) {
    return this.hasTeam(general);
  }
  static generateErrors(general) {
    const hasNotTeam = !this.hasTeam(general);
    return hasNotTeam ? [this.teamIsMandatory] : [];
  }
  static hasTeam(general) {
    return general.team !== null;
  }
  static get teamIsMandatory() {
    return "Une \xE9quipe responsable est n\xE9cessaire";
  }
};
var MobilizationsSpecification = class _MobilizationsSpecification {
  static {
    __name(this, "MobilizationsSpecification");
  }
  static isSatisfiedBy(mobilizations) {
    return this.hasMobilization(mobilizations) && mobilizations.every(_MobilizationsSpecification.hasRequest);
  }
  static generateErrors(mobilizations) {
    return [
      ...this.minumumMobilizationError(mobilizations),
      ...this.requestError(mobilizations)
    ];
  }
  static requestError(mobilizations) {
    const isMissingRequest = mobilizations.some(
      (mobilization) => !this.hasRequest(mobilization)
    );
    return isMissingRequest ? [this.allMobilizationsHaveRequest] : [];
  }
  static minumumMobilizationError(mobilizations) {
    const hasNotMobilizations = !this.hasMobilization(mobilizations);
    return hasNotMobilizations ? [this.atLeastOneMobilizationIsMandatory] : [];
  }
  static hasMobilization(mobilizations) {
    return hasAtLeastOneItem2(mobilizations);
  }
  static hasRequest(mobilization) {
    const requestVolunteers = hasAtLeastOneItem2(mobilization.volunteers);
    const requestTeamMembers = hasAtLeastOneItem2(mobilization.teams);
    return requestVolunteers || requestTeamMembers;
  }
  static get atLeastOneMobilizationIsMandatory() {
    return "Au moins une mobilisation est n\xE9cessaire";
  }
  static get allMobilizationsHaveRequest() {
    return "Toutes les mobilisations doivent demander au moins une personne (nominativement ou via les \xE9quipes)";
  }
};
var InstructionsSpecification = class {
  static {
    __name(this, "InstructionsSpecification");
  }
  static isSatisfiedBy(instructions) {
    return InChargeInstructionsSpecification.isSatisfiedBy(instructions.inCharge) && hasAtLeastOneItem2(instructions.contacts) && instructions.appointment !== null && instructions.global !== null;
  }
  static generateErrors(instructions) {
    return [
      ...this.appointmentErrors(instructions.appointment),
      ...this.globalInstructionErrors(instructions.global),
      ...this.contactErrors(instructions.contacts),
      ...InChargeInstructionsSpecification.generateErrors(
        instructions.inCharge
      )
    ];
  }
  static contactErrors(contacts) {
    const hasNotAnyContact = !hasAtLeastOneItem2(contacts);
    return hasNotAnyContact ? [this.atLeastOneContactIsMandatory] : [];
  }
  static appointmentErrors(appointment) {
    const hasNotAppointment = appointment === null;
    return hasNotAppointment ? [this.appointmentIsMandatory] : [];
  }
  static globalInstructionErrors(global) {
    const hasNotGlobalInstruction = global === null;
    return hasNotGlobalInstruction ? [this.globalInstructionIsMandatory] : [];
  }
  static get atLeastOneContactIsMandatory() {
    return "Au moins une personne \xE0 contacter est n\xE9cessaire";
  }
  static get appointmentIsMandatory() {
    return "Un lieu de rendez-vous est n\xE9cessaire";
  }
  static get globalInstructionIsMandatory() {
    return "Des instructions sont n\xE9cessaires";
  }
};
var InChargeInstructionsSpecification = class {
  static {
    __name(this, "InChargeInstructionsSpecification");
  }
  static isSatisfiedBy(inCharge) {
    return this.isFilled(inCharge) || this.isEmpty(inCharge);
  }
  static isFilled(inCharge) {
    return hasAtLeastOneItem2(inCharge.volunteers) && inCharge.instruction !== null;
  }
  static isEmpty(inCharge) {
    return inCharge.instruction === null && isEmpty(inCharge.volunteers);
  }
  static generateErrors(inCharge) {
    if (this.isFilled(inCharge) || this.isEmpty(inCharge)) return [];
    const hasNotInstructions = inCharge.instruction === null;
    return hasNotInstructions ? [this.instructionIsMandatory] : [this.volunteerIsMandatory];
  }
  static get volunteerIsMandatory() {
    return "Des responsables sont n\xE9cessaires pour les instructions sp\xE9cifiques";
  }
  static get instructionIsMandatory() {
    return "Des instructions sp\xE9cifiques sont n\xE9cessaires pour les responsables";
  }
};

// src/festival-task/ask-for-review/ask-for-review.error.ts
var AskForReviewError = class extends ReadyForReviewError {
  static {
    __name(this, "AskForReviewError");
  }
  constructor(task) {
    super(InReviewSpecification.generateErrors(task), "FT");
  }
};

// src/festival-task/ask-for-review/in-review-festival-task.ts
var NO_SUPPLY_REQUEST_TASK_REVIEWS = {
  elec: NOT_ASKING_TO_REVIEW,
  matos: REVIEWING,
  humain: REVIEWING
};
var TASK_WITH_SUPPLY_REQUEST_REVIEWS = {
  elec: REVIEWING,
  matos: REVIEWING,
  humain: REVIEWING
};
var COMMON_REVIEWERS2 = [humain, matos];
var SUPPLY_REQUEST_REVIEWERS = [...COMMON_REVIEWERS2, elec];
var InReviewFestivalTask = class _InReviewFestivalTask {
  constructor(_task, previousReview) {
    this._task = _task;
    this.previousReview = previousReview;
  }
  static {
    __name(this, "InReviewFestivalTask");
  }
  static fromDraft(task, instigator, reviewer) {
    if (!InReviewSpecification.isSatisfiedBy(task)) {
      throw new AskForReviewError(task);
    }
    const inReview = {
      ...task,
      history: this.addReadyToReviewEvent(task.history, instigator),
      reviews: this.initReviews(task.festivalActivity),
      reviewer
    };
    return new _InReviewFestivalTask(inReview);
  }
  static fromRefused(task, instigator) {
    const history = this.addReadyToReviewEvent(task.history, instigator);
    const reviews = _InReviewFestivalTask.resetReviews(task.reviews);
    const inReview = { ...task, history, reviews };
    return new _InReviewFestivalTask(inReview, task.reviews);
  }
  static initReviews({ hasSupplyRequest }) {
    if (hasSupplyRequest) return TASK_WITH_SUPPLY_REQUEST_REVIEWS;
    return NO_SUPPLY_REQUEST_TASK_REVIEWS;
  }
  static resetReviews(reviews) {
    return {
      humain: reviews.humain === REJECTED ? REVIEWING : reviews.humain,
      matos: reviews.matos === REJECTED ? REVIEWING : reviews.matos,
      elec: reviews.elec === REJECTED ? REVIEWING : reviews.elec
    };
  }
  static addReadyToReviewEvent(history, instigator) {
    const readyToReview = FestivalTaskKeyEvents.readyToReview(instigator);
    return [...history, readyToReview];
  }
  get event() {
    const reviewers = this.reviewers;
    return { id: this._task.id, name: this._task.general.name, reviewers };
  }
  get reviewers() {
    const { hasSupplyRequest } = this._task.festivalActivity;
    if (!this.previousReview) {
      return hasSupplyRequest ? SUPPLY_REQUEST_REVIEWERS : COMMON_REVIEWERS2;
    }
    return SUPPLY_REQUEST_REVIEWERS.filter(
      (reviewer) => this.hasRefused(reviewer)
    );
  }
  hasRefused(reviewer) {
    switch (reviewer) {
      case humain:
        return this.previousReview?.humain === REJECTED;
      case matos:
        return this.previousReview?.matos === REJECTED;
      case elec:
        return this.previousReview?.elec === REJECTED;
    }
  }
  get task() {
    return { ...this._task, status: IN_REVIEW6 };
  }
};

// src/festival-task/ask-for-review/ask-for-review.ts
var AskForReview2 = class {
  constructor(repositories, translator) {
    this.repositories = repositories;
    this.translator = translator;
  }
  static {
    __name(this, "AskForReview");
  }
  async from(taskId, instigator) {
    const task = await this.repositories.tasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!isDraft(task) && !isRefused(task)) {
      throw new CantAskForReview(taskId);
    }
    const inReview = await this.convertInReview(task, instigator);
    this.repositories.notifications.add(inReview.event);
    const saved = await this.repositories.tasks.save(inReview.task);
    return this.translator.translate(saved);
  }
  async convertInReview(task, instigator) {
    if (isDraft(task)) {
      const reviewer = await this.findReviewer();
      return InReviewFestivalTask.fromDraft(task, instigator, reviewer);
    }
    return InReviewFestivalTask.fromRefused(task, instigator);
  }
  async findReviewer() {
    const reviewers = await this.repositories.reviewers.getAll();
    const minReviewsCount = Math.min(...reviewers.map(({ count }) => count));
    const reviewer = reviewers.find(({ count }) => count === minReviewsCount);
    if (!reviewer) throw new FestivalTaskError("Aucun relecteur disponible");
    return reviewer.adherent;
  }
};
function isReviewer2(team) {
  return [humain, matos, elec].includes(team);
}
__name(isReviewer2, "isReviewer");

// src/festival-task/prepare/prepare.ts
import { DRAFT as DRAFT6, IN_REVIEW as IN_REVIEW8, REFUSED as REFUSED6 } from "@overbookd/festival-event-constants";

// src/festival-task/festival-task.ts
import {
  READY_TO_ASSIGN
} from "@overbookd/festival-event-constants";
function isReadyToAssign(task) {
  return task.status === READY_TO_ASSIGN;
}
__name(isReadyToAssign, "isReadyToAssign");

// src/festival-task/prepare/sections/mobilizations.ts
import { Duration as Duration2, Period as Period2 } from "@overbookd/time";
import { updateItemToList as updateItemToList6 } from "@overbookd/list";
var SplitablePeriod = class {
  static {
    __name(this, "SplitablePeriod");
  }
  static checkValidity({ start, end, splitDuration }) {
    const period = Period2.init({ start, end });
    if (!period.duration.canBeDividedBy(splitDuration)) {
      throw new SplitDurationIsNotPeriodDivider(splitDuration);
    }
    return;
  }
};
var Mobilizations = class _Mobilizations {
  constructor(mobilizations) {
    this.mobilizations = mobilizations;
  }
  static {
    __name(this, "Mobilizations");
  }
  static build(mobilizations) {
    return new _Mobilizations(mobilizations);
  }
  add(form) {
    const mobilization = MobilizationFactory.init(form).json;
    if (this.has(mobilization)) throw new MobilizationAlreadyExist();
    return new _Mobilizations([...this.mobilizations, mobilization]);
  }
  remove(mobilizationId) {
    return new _Mobilizations(
      this.mobilizations.filter(({ id }) => id !== mobilizationId)
    );
  }
  update(mobilizationId, update) {
    const { index, value } = this.retrieveMobilization(mobilizationId);
    if (index === -1 || !value) throw new MobilizationNotFound();
    const builder = MobilizationFactory.build(value);
    const mobilizations = updateItemToList6(
      this.mobilizations,
      index,
      builder.update(update).json
    );
    return new _Mobilizations(mobilizations);
  }
  addTeamTo(mobilizationId, team) {
    const { index, value } = this.retrieveMobilization(mobilizationId);
    if (index === -1 || !value) return this;
    const builder = MobilizationFactory.build(value);
    const mobilizations = updateItemToList6(
      this.mobilizations,
      index,
      builder.addTeam(team).json
    );
    return new _Mobilizations(mobilizations);
  }
  removeTeamFrom(mobilizationId, team) {
    const { index, value } = this.retrieveMobilization(mobilizationId);
    if (index === -1 || !value) return this;
    const builder = MobilizationFactory.build(value);
    const mobilizations = updateItemToList6(
      this.mobilizations,
      index,
      builder.removeTeam(team).json
    );
    return new _Mobilizations(mobilizations);
  }
  addVolunteerTo(mobilizationId, volunteer) {
    const { index, value } = this.retrieveMobilization(mobilizationId);
    if (index === -1 || !value) return this;
    const builder = MobilizationFactory.build(value);
    const mobilizations = updateItemToList6(
      this.mobilizations,
      index,
      builder.addVolunteer(volunteer).json
    );
    return new _Mobilizations(mobilizations);
  }
  removeVolunteerFrom(mobilizationId, volunteerId) {
    const { index, value } = this.retrieveMobilization(mobilizationId);
    if (index === -1 || !value) return this;
    const builder = MobilizationFactory.build(value);
    const mobilizations = updateItemToList6(
      this.mobilizations,
      index,
      builder.removeVolunteer(volunteerId).json
    );
    return new _Mobilizations(mobilizations);
  }
  retrieveMobilization(id) {
    const index = this.mobilizations.findIndex(
      ({ id: currentId }) => currentId === id
    );
    const value = this.mobilizations.at(index);
    return { index, value };
  }
  has(mobilization) {
    return this.mobilizations.some(({ id }) => id === mobilization.id);
  }
  get json() {
    return [...this.mobilizations];
  }
};
var MobilizationFactory = class _MobilizationFactory {
  constructor(mobilization) {
    this.mobilization = mobilization;
  }
  static {
    __name(this, "MobilizationFactory");
  }
  static init(form) {
    const { durationSplitInHour, teams, volunteers, ...period } = form;
    this.checkPeriod(durationSplitInHour, period);
    const id = this.generateId(period);
    return new _MobilizationFactory({ ...form, id });
  }
  static build(mobilization) {
    return new _MobilizationFactory(mobilization);
  }
  static checkPeriod(durationSplitInHour, period) {
    if (!durationSplitInHour) {
      return Period2.init(period);
    }
    const splitDuration = Duration2.hours(durationSplitInHour);
    return SplitablePeriod.checkValidity({ ...period, splitDuration });
  }
  static generateId(period) {
    const { start, end } = period;
    const startMinutes = Duration2.ms(start.getTime()).inMinutes;
    const endMinutes = Duration2.ms(end.getTime()).inMinutes;
    return `${startMinutes}-${endMinutes}`;
  }
  update(update) {
    const form = { ...this.mobilization, ...update };
    return _MobilizationFactory.init(form);
  }
  addTeam(teamToAdd) {
    const existingTeamIndex = this.mobilization.teams.findIndex(
      (request) => request.team === teamToAdd.team
    );
    const alreadyExists = existingTeamIndex !== -1;
    const teams = alreadyExists ? updateItemToList6(this.mobilization.teams, existingTeamIndex, teamToAdd) : [...this.mobilization.teams, teamToAdd];
    return new _MobilizationFactory({ ...this.mobilization, teams });
  }
  removeTeam(team) {
    const teams = this.mobilization.teams.filter((t) => t.team !== team);
    return new _MobilizationFactory({ ...this.mobilization, teams });
  }
  addVolunteer(volunteer) {
    if (this.hasVolunteer(volunteer)) return this;
    const volunteers = [
      ...this.mobilization.volunteers,
      { ...volunteer, conflicts: [] }
    ];
    return new _MobilizationFactory({ ...this.mobilization, volunteers });
  }
  removeVolunteer(volunteerId) {
    const volunteers = this.mobilization.volunteers.filter(
      ({ id }) => id !== volunteerId
    );
    return new _MobilizationFactory({ ...this.mobilization, volunteers });
  }
  hasVolunteer(volunteer) {
    return this.mobilization.volunteers.some(({ id }) => id === volunteer.id);
  }
  get json() {
    return this.mobilization;
  }
};

// src/festival-task/prepare/sections/inquiries.ts
var Inquiries2 = class _Inquiries {
  constructor(inquiries) {
    this.inquiries = inquiries;
  }
  static {
    __name(this, "Inquiries");
  }
  static build(inquiries) {
    return new _Inquiries(inquiries);
  }
  add(inquiry) {
    if (this.has(inquiry)) throw new GearAlreadyRequested(inquiry.name);
    return new _Inquiries([...this.inquiries, inquiry]);
  }
  remove(slug) {
    const inquiries = this.inquiries.filter((inquiry) => inquiry.slug !== slug);
    return new _Inquiries(inquiries);
  }
  assignToDrive(link) {
    const inquiries = this.inquiries.map(
      (inquiry) => inquiry.slug === link.slug ? { ...inquiry, drive: link.drive } : inquiry
    );
    return new _Inquiries(inquiries);
  }
  has(inquiry) {
    return this.inquiries.some(({ slug }) => slug === inquiry.slug);
  }
  get json() {
    return [...this.inquiries];
  }
};

// src/festival-task/sections/instructions.ts
function hasInChargeInstructions(inCharge) {
  return inCharge.volunteers.length > 0;
}
__name(hasInChargeInstructions, "hasInChargeInstructions");

// src/festival-task/prepare/sections/instructions.ts
function isForcingInChargeUpdate(forceInstructions) {
  return Object.hasOwn(forceInstructions, "inCharge");
}
__name(isForcingInChargeUpdate, "isForcingInChargeUpdate");
function isForcingGlobalUpdate(forceInstructions) {
  return Object.hasOwn(forceInstructions, "global");
}
__name(isForcingGlobalUpdate, "isForcingGlobalUpdate");
var Instructions = class _Instructions {
  constructor(instructions) {
    this.instructions = instructions;
  }
  static {
    __name(this, "Instructions");
  }
  static build(instructions) {
    return new _Instructions(instructions);
  }
  static forceUpdate(currentInstructions, force) {
    const global = isForcingGlobalUpdate(force) ? force.global : currentInstructions.global;
    const currentInCharge = currentInstructions.inCharge;
    const inCharge = isForcingInChargeUpdate(force) && hasInChargeInstructions(currentInCharge) ? { ...currentInCharge, instruction: force.inCharge } : currentInCharge;
    const instructions = {
      ...currentInstructions,
      global,
      inCharge
    };
    return instructions;
  }
  update({ inCharge, ...form }) {
    const inChargeBuilder = InCharge.build(this.instructions.inCharge);
    const updatedInCharge = inChargeBuilder.update(inCharge).json;
    const instructions = {
      ...this.instructions,
      ...form,
      inCharge: updatedInCharge
    };
    return new _Instructions(instructions);
  }
  addContact(contact) {
    const contactsBuilder = Contacts.build(this.instructions.contacts);
    const contacts = contactsBuilder.add(contact).json;
    return new _Instructions({ ...this.instructions, contacts });
  }
  removeContact(contactId) {
    const contactsBuilder = Contacts.build(this.instructions.contacts);
    const contacts = contactsBuilder.remove(contactId).json;
    return new _Instructions({ ...this.instructions, contacts });
  }
  addVolunteer(volunteer) {
    const inChargeBuilder = InCharge.build(this.instructions.inCharge);
    const inCharge = inChargeBuilder.addVolunteer(volunteer).json;
    return new _Instructions({ ...this.instructions, inCharge });
  }
  removeVolunteer(volunteerId) {
    const inChargeBuilder = InCharge.build(this.instructions.inCharge);
    const inCharge = inChargeBuilder.removeVolunteer(volunteerId).json;
    return new _Instructions({ ...this.instructions, inCharge });
  }
  clearInCharge() {
    const inChargeBuilder = InCharge.build(this.instructions.inCharge);
    const inCharge = inChargeBuilder.clear().json;
    return new _Instructions({ ...this.instructions, inCharge });
  }
  initInCharge(volunteers, instruction) {
    const inCharge = InCharge.build({ volunteers, instruction }).json;
    return new _Instructions({ ...this.instructions, inCharge });
  }
  get json() {
    return { ...this.instructions };
  }
};
var InCharge = class _InCharge {
  constructor(inCharge) {
    this.inCharge = inCharge;
  }
  static {
    __name(this, "InCharge");
  }
  static build(inCharge) {
    return new _InCharge(inCharge);
  }
  update(instruction) {
    if (instruction === void 0) return this;
    return new _InCharge({ ...this.inCharge, instruction });
  }
  addVolunteer(volunteer) {
    const volunteerBuilder = Volunteers.build(this.inCharge.volunteers);
    const volunteers = volunteerBuilder.add(volunteer).json;
    return new _InCharge({ ...this.inCharge, volunteers });
  }
  removeVolunteer(volunteerId) {
    const volunteerBuilder = Volunteers.build(this.inCharge.volunteers);
    const volunteers = volunteerBuilder.remove(volunteerId).json;
    return new _InCharge({ ...this.inCharge, volunteers });
  }
  clear() {
    const volunteers = Volunteers.build([]).json;
    const instruction = null;
    return new _InCharge({ ...this.inCharge, volunteers, instruction });
  }
  get json() {
    return { ...this.inCharge };
  }
};
var Contacts = class _Contacts {
  constructor(contacts) {
    this.contacts = contacts;
  }
  static {
    __name(this, "Contacts");
  }
  static build(contacts) {
    return new _Contacts(contacts);
  }
  add(contact) {
    if (this.has(contact)) return this;
    return new _Contacts([...this.contacts, contact]);
  }
  remove(contactId) {
    return new _Contacts(this.contacts.filter(({ id }) => id !== contactId));
  }
  has(contact) {
    return this.contacts.some(({ id }) => id === contact.id);
  }
  get json() {
    return [...this.contacts];
  }
};
var Volunteers = class _Volunteers {
  constructor(volunteers) {
    this.volunteers = volunteers;
  }
  static {
    __name(this, "Volunteers");
  }
  static build(volunteers) {
    return new _Volunteers(volunteers);
  }
  add(volunteer) {
    if (this.has(volunteer)) return this;
    return new _Volunteers([...this.volunteers, volunteer]);
  }
  remove(volunteerId) {
    const volunteers = this.volunteers.filter(({ id }) => id !== volunteerId);
    return new _Volunteers(volunteers);
  }
  has(volunteer) {
    return this.volunteers.some(({ id }) => id === volunteer.id);
  }
  get json() {
    return [...this.volunteers];
  }
};

// src/common/time-window.ts
import { OverDate, ONE_MINUTE_IN_MS } from "@overbookd/time";
function readablePeriodFrom({ start, end }) {
  const locales = "fr-FR";
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris"
  };
  const startString = start.toLocaleDateString(locales, options);
  const endString = end.toLocaleDateString(locales, options);
  return `du ${startString} au ${endString}`;
}
__name(readablePeriodFrom, "readablePeriodFrom");
function readablePeriodFromId(id) {
  const startTimestamp = parseInt(id.split("-")[0]) * ONE_MINUTE_IN_MS;
  const endTimestamp = parseInt(id.split("-")[1]) * ONE_MINUTE_IN_MS;
  const start = OverDate.from(new Date(startTimestamp)).date;
  const end = OverDate.from(new Date(endTimestamp)).date;
  return readablePeriodFrom({ start, end });
}
__name(readablePeriodFromId, "readablePeriodFromId");

// src/festival-task/prepare/prepare.ts
var PrepareFestivalTaskError = class extends FestivalTaskError {
  static {
    __name(this, "PrepareFestivalTaskError");
  }
  constructor(errors) {
    super(errors.join("\n"));
  }
};
var PrepareFestivalTask = class {
  constructor(festivalTasks, festivalTaskTranslator) {
    this.festivalTasks = festivalTasks;
    this.festivalTaskTranslator = festivalTaskTranslator;
  }
  static {
    __name(this, "PrepareFestivalTask");
  }
  async updateGeneralSection(taskId, update) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const general = { ...task.general, ...update };
    const updatedTask = checkValidity({ ...task, general });
    return this.save(updatedTask);
  }
  async updateInstructionsSection(taskId, update, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.canUpdateInstructions(task, update)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.update(update).json;
    const validTask = checkValidity({ ...task, instructions });
    const field = update.global ? "instructions" : "instructions des responsables";
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      `un changement sur le champ ${field}`
    );
    return this.save(updatedTask);
  }
  async forceInstructions(taskId, force, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!isReadyToAssign(task)) {
      throw ForceUpdateError.notReadyToAssign(task.id);
    }
    const instructions = Instructions.forceUpdate(task.instructions, force);
    const history = [
      ...task.history,
      FestivalTaskKeyEvents.forceInstructions(instigator)
    ];
    return this.save({ ...task, instructions, history });
  }
  canUpdateInstructions(task, update) {
    const isUpdatingSharedFields = update.global !== void 0 || update.inCharge !== void 0;
    return isUpdatingSharedFields ? this.hasReviewersAllowUpdate(task) : !isApprovedBy(humain, task);
  }
  async addContact(taskId, contact) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.addContact(contact).json;
    const updatedTask = checkValidity({ ...task, instructions });
    return this.save(updatedTask);
  }
  async removeContact(taskId, contactId) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.removeContact(contactId).json;
    const updatedTask = checkValidity({ ...task, instructions });
    return this.save(updatedTask);
  }
  async addInChargeVolunteer(taskId, volunteer) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.addVolunteer(volunteer).json;
    const updatedTask = checkValidity({ ...task, instructions });
    return this.save(updatedTask);
  }
  async removeInChargeVolunteer(taskId, volunteerId) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.removeVolunteer(volunteerId).json;
    const updatedTask = checkValidity({ ...task, instructions });
    return this.save(updatedTask);
  }
  async clearInCharge(taskId, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.hasReviewersAllowUpdate(task)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.clearInCharge().json;
    const validTask = checkValidity({ ...task, instructions });
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      "la suppression des instructions des responsables"
    );
    return this.save(updatedTask);
  }
  async initInCharge(taskId, { volunteers, instruction }, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.hasReviewersAllowUpdate(task)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Instructions.build(task.instructions);
    const instructions = builder.initInCharge(volunteers, instruction).json;
    const validTask = checkValidity({ ...task, instructions });
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      "l'initialisation des instructions des responsables"
    );
    return this.save(updatedTask);
  }
  async addMobilization(taskId, mobilization, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.hasReviewersAllowUpdate(task)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.add(mobilization).json;
    const validTask = checkValidity({ ...task, mobilizations });
    const readablePeriod = readablePeriodFrom(mobilization);
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      `l'ajout d'une mobilisation ${readablePeriod}`
    );
    return this.save(updatedTask);
  }
  async removeMobilization(taskId, mobilizationId, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.hasReviewersAllowUpdate(task)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.remove(mobilizationId).json;
    const validTask = checkValidity({ ...task, mobilizations });
    const readablePeriod = readablePeriodFromId(mobilizationId);
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      `la suppression de la mobilisation ${readablePeriod}`
    );
    return this.save(updatedTask);
  }
  async updateMobilization(taskId, mobilizationId, update, instigator) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (!this.hasReviewersAllowUpdate(task)) {
      const approvers = extractApprovers(task);
      throw new AlreadyApprovedBy(approvers, "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.update(mobilizationId, update).json;
    const validTask = checkValidity({ ...task, mobilizations });
    const readablePeriod = readablePeriodFromId(mobilizationId);
    const updatedTask = this.resetApproversReviewOnRefusedTask(
      validTask,
      instigator,
      `un changement sur la mobilisation ${readablePeriod}`
    );
    return this.save(updatedTask);
  }
  async addTeamToMobilization(taskId, mobilizationId, team) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.addTeamTo(mobilizationId, team).json;
    const updatedTask = checkValidity({ ...task, mobilizations });
    return this.save(updatedTask);
  }
  async removeTeamFromMobilization(taskId, mobilizationId, team) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.removeTeamFrom(mobilizationId, team).json;
    const updatedTask = checkValidity({ ...task, mobilizations });
    return this.save(updatedTask);
  }
  async addVolunteerToMobilization(taskId, mobilizationId, volunteer) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.addVolunteerTo(
      mobilizationId,
      volunteer
    ).json;
    const updatedTask = checkValidity({ ...task, mobilizations });
    return this.save(updatedTask);
  }
  async removeVolunteerFromMobilization(taskId, mobilizationId, volunteerId) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isValidated(task) || isReadyToAssign(task)) {
      throw new FestivalTaskError("Cas non g\xE9r\xE9");
    }
    if (isApprovedBy(humain, task)) {
      throw new AlreadyApprovedBy([humain], "FT");
    }
    const builder = Mobilizations.build(task.mobilizations);
    const mobilizations = builder.removeVolunteerFrom(
      mobilizationId,
      volunteerId
    ).json;
    const updatedTask = checkValidity({ ...task, mobilizations });
    return this.save(updatedTask);
  }
  async addInquiry(taskId, inquiry) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isApprovedBy(matos, task)) {
      throw new AlreadyApprovedBy([matos], "FT");
    }
    const builder = Inquiries2.build(task.inquiries);
    const inquiries = builder.add(inquiry).json;
    return this.save({ ...task, inquiries });
  }
  async removeInquiry(taskId, slug) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isApprovedBy(matos, task)) {
      throw new AlreadyApprovedBy([matos], "FT");
    }
    const builder = Inquiries2.build(task.inquiries);
    const inquiries = builder.remove(slug).json;
    return this.save({ ...task, inquiries });
  }
  async assignInquiryToDrive(taskId, link) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (isDraft(task)) throw new AssignDriveInDraft("FT");
    const builder = Inquiries2.build(task.inquiries);
    const inquiries = builder.assignToDrive(link).json;
    return this.save({ ...task, inquiries });
  }
  async publishFeedback(taskId, { author, content }) {
    const task = await this.festivalTasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    const feedback = { author, content, publishedAt: /* @__PURE__ */ new Date() };
    const feedbacks = [...task.feedbacks, feedback];
    return this.save({ ...task, feedbacks });
  }
  async save(toSave) {
    const updated = await this.festivalTasks.save(toSave);
    return this.festivalTaskTranslator.translate(updated);
  }
  hasReviewersAllowUpdate(task) {
    if (isDraft(task) || isRefused(task)) return true;
    if (isValidated(task) || isReadyToAssign(task)) return false;
    return this.areNoneOfTheReviewersApproved(task);
  }
  areNoneOfTheReviewersApproved(task) {
    return !isApprovedBy(humain, task) && !isApprovedBy(matos, task) && !isApprovedBy(elec, task);
  }
  resetApproversReviewOnRefusedTask(task, instigator, reason) {
    if (!isRefused(task)) return task;
    if (this.areNoneOfTheReviewersApproved(task)) return task;
    const humain2 = this.resetApproval(task.reviews.humain);
    const matos2 = this.resetApproval(task.reviews.matos);
    const elec2 = this.resetApproval(task.reviews.elec);
    const history = [
      ...task.history,
      FestivalTaskKeyEvents.resetReview(instigator, reason)
    ];
    return { ...task, reviews: { humain: humain2, matos: matos2, elec: elec2 }, history };
  }
  resetApproval(previous) {
    return previous === APPROVED ? REVIEWING : previous;
  }
};
function isApprovedBy(reviewer, task) {
  if (isDraft(task)) return false;
  switch (reviewer) {
    case humain:
      return task.reviews.humain === APPROVED;
    case matos:
      return task.reviews.matos === APPROVED;
    case elec:
      return task.reviews.elec === APPROVED;
  }
}
__name(isApprovedBy, "isApprovedBy");
function extractApprovers(task) {
  const reviewers = [humain, matos, elec];
  return reviewers.filter((reviewer) => isApprovedBy(reviewer, task));
}
__name(extractApprovers, "extractApprovers");
function checkValidity(task) {
  switch (task.status) {
    case DRAFT6:
      return task;
    case IN_REVIEW8:
    case REFUSED6: {
      if (!InReviewSpecification.isSatisfiedBy(task)) {
        const errors = InReviewSpecification.generateErrors(task);
        throw new PrepareFestivalTaskError(errors);
      }
      return task;
    }
    default:
      throw new FestivalTaskError("Pas encore support\xE9");
  }
}
__name(checkValidity, "checkValidity");

// src/festival-task/view/view.ts
var ViewFestivalTask = class {
  constructor(festivalTasks, festivalTaskTranslator) {
    this.festivalTasks = festivalTasks;
    this.festivalTaskTranslator = festivalTaskTranslator;
  }
  static {
    __name(this, "ViewFestivalTask");
  }
  all() {
    return this.festivalTasks.all();
  }
  async one(ftId) {
    const task = await this.festivalTasks.one(ftId);
    if (!task) throw new FestivalTaskNotFound(ftId);
    return this.festivalTaskTranslator.translate(task);
  }
};

// src/festival-task/enable-assignment/enable-assignment.ts
import { READY_TO_ASSIGN as READY_TO_ASSIGN2 } from "@overbookd/festival-event-constants";
import { Period as Period3, Duration as Duration3 } from "@overbookd/time";
var EnableAssignment = class {
  constructor(festivalTasks, festivalTaskTranslator) {
    this.festivalTasks = festivalTasks;
    this.festivalTaskTranslator = festivalTaskTranslator;
  }
  static {
    __name(this, "EnableAssignment");
  }
  async for(ftId, instigator, categorize) {
    const task = await this.festivalTasks.findById(ftId);
    if (!task) throw new FestivalTaskNotFound(ftId);
    if (isReadyToAssign(task)) {
      throw new FestivalTaskError(
        "La t\xE2che est d\xE9j\xE0 en affectation, ce n'est pas normal"
      );
    }
    if (!isValidated(task)) throw new FestivalTaskNotValidated(ftId);
    const readyToAssign = ReadyToAssignFestivalTask.fromValidated(
      await this.festivalTaskTranslator.translate(task),
      instigator,
      categorize
    );
    const stored = await this.festivalTasks.save(readyToAssign);
    return this.festivalTaskTranslator.translate(stored);
  }
};
var ReadyToAssignFestivalTask = class _ReadyToAssignFestivalTask {
  static {
    __name(this, "ReadyToAssignFestivalTask");
  }
  static fromValidated(task, instigator, categorize) {
    if (_ReadyToAssignFestivalTask.hasUnavailableVolunteerRequired(task)) {
      throw new ReadyToAssignError();
    }
    const history = [
      ...task.history,
      FestivalTaskKeyEvents.assignmentStarted(instigator)
    ];
    const mobilizations = task.mobilizations.map(
      (mobilization) => Assignments.generate(mobilization)
    );
    return {
      ...task,
      ...categorize,
      status: READY_TO_ASSIGN2,
      history,
      mobilizations
    };
  }
  static hasUnavailableVolunteerRequired(task) {
    return task.mobilizations.some(
      ({ volunteers }) => volunteers.some(({ conflicts }) => {
        const missingAvailability = conflicts.availability === true;
        const alreadyAssigned = conflicts.assignments.length > 0;
        return missingAvailability || alreadyAssigned;
      })
    );
  }
};
var Assignments = class _Assignments {
  static {
    __name(this, "Assignments");
  }
  static generate(mobilization) {
    const assignmentPeriods = _Assignments.generatePeriods(mobilization);
    const assignments = assignmentPeriods.map(
      (period) => extractAssignment(mobilization, period)
    );
    return { ...mobilization, assignments };
  }
  static generatePeriods(mobilization) {
    const mobilizationPeriod = Period3.init(mobilization);
    if (mobilization.durationSplitInHour === null) return [mobilizationPeriod];
    return mobilizationPeriod.splitWithIntervalInMs(
      Duration3.hours(mobilization.durationSplitInHour).inMilliseconds
    );
  }
};
function extractAssignment(mobilization, period) {
  return {
    start: period.start,
    end: period.end,
    id: period.id,
    assignees: mobilization.volunteers.map(extractVolunteerData)
  };
}
__name(extractAssignment, "extractAssignment");
function extractVolunteerData(volunteer) {
  return {
    id: volunteer.id,
    lastname: volunteer.lastname,
    firstname: volunteer.firstname,
    nickname: volunteer.nickname
  };
}
__name(extractVolunteerData, "extractVolunteerData");

// src/festival-task/sections/mobilizations.ts
var benevole = "benevole";
var hard = "hard";
var confiance = "confiance";
var vieux = "vieux";
var conducteur = "conducteur";
var bde = "bde";
var karna = "karna";
var kfet = "kfet";
var strasbourg = "strasbourg";
var soft = "soft";
var cvl = "cvl";
var teckos = "teckos";
var montage = "team-montage";
var requirableTeams = [
  benevole,
  hard,
  confiance,
  vieux,
  conducteur,
  bde,
  karna,
  kfet,
  strasbourg,
  cvl,
  teckos,
  montage
];
var requirableTeamsExtended = [...requirableTeams, soft];

// src/festival-task/volunteer-conflicts.ts
var FestivalTaskTranslator = class {
  constructor(volunteerConflicts) {
    this.volunteerConflicts = volunteerConflicts;
  }
  static {
    __name(this, "FestivalTaskTranslator");
  }
  async translate(task) {
    const mobilizations = await Promise.all(
      task.mobilizations.map(async (mobilization) => {
        const volunteers = await this.assignConflictsToVolunteers(
          mobilization,
          task.id
        );
        return { ...mobilization, volunteers };
      })
    );
    const translated = { ...task, mobilizations };
    if (!isWithConflicts(translated)) {
      throw new FestivalTaskError("Impossible de rajouter les conflits.");
    }
    return translated;
  }
  async assignConflictsToVolunteers(mobilization, taskId) {
    return Promise.all(
      mobilization.volunteers.map(async (volunteer) => {
        const period = { start: mobilization.start, end: mobilization.end };
        const conflicts = await this.volunteerConflicts.on(
          taskId,
          period,
          volunteer.id
        );
        return { ...volunteer, conflicts };
      })
    );
  }
};
function isWithConflicts(task) {
  return task.mobilizations.every(
    (mobilization) => mobilization.volunteers.every(
      (volunteer) => Object.hasOwn(volunteer, "conflicts")
    )
  );
}
__name(isWithConflicts, "isWithConflicts");

// src/festival-task/review/review.ts
import { REFUSED as REFUSED7, VALIDATED as VALIDATED6 } from "@overbookd/festival-event-constants";
var Reject = class {
  static {
    __name(this, "Reject");
  }
  static from(task, rejection) {
    const reviews = {
      ...task.reviews,
      [rejection.team]: REJECTED
    };
    const status = REFUSED7;
    const history = [
      ...task.history,
      FestivalTaskKeyEvents.rejected(rejection.rejector, rejection.reason)
    ];
    return { ...task, reviews, status, history };
  }
};
var Approve = class _Approve {
  static {
    __name(this, "Approve");
  }
  static from(task, approval) {
    const reviews = { ...task.reviews, [approval.team]: APPROVED };
    const history = [
      ...task.history,
      FestivalTaskKeyEvents.approved(approval.reviewer)
    ];
    if (_Approve.hasAllApproved(reviews)) {
      return { ...task, reviews, status: VALIDATED6, history };
    }
    return { ...task, reviews, history };
  }
  static hasAllApproved(reviews) {
    return Object.values(reviews).every(
      (review) => review === APPROVED || review === NOT_ASKING_TO_REVIEW
    );
  }
};
var Review = class {
  constructor(tasks, translator) {
    this.tasks = tasks;
    this.translator = translator;
  }
  static {
    __name(this, "Review");
  }
  async reject(taskId, rejection) {
    const task = await this.tasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (task.reviews[rejection.team] === NOT_ASKING_TO_REVIEW) {
      throw new NotAskingToReview(task.id, rejection.team, "FT");
    }
    const rejected = await this.tasks.save(Reject.from(task, rejection));
    return this.translator.translate(rejected);
  }
  async approve(taskId, approval) {
    const task = await this.tasks.findById(taskId);
    if (!task) throw new FestivalTaskNotFound(taskId);
    if (task.reviews[approval.team] === NOT_ASKING_TO_REVIEW) {
      throw new NotAskingToReview(task.id, approval.team, "FT");
    }
    if (this.isAlreadyApprovedBy(task, approval.team)) {
      throw new AlreadyApproved(task.id, approval.team, "FT");
    }
    if (approval.team === matos) this.checkInquiryDriveAssignment(task);
    const approved = Approve.from(task, approval);
    const saved = await this.tasks.save(approved);
    return this.translator.translate(saved);
  }
  checkInquiryDriveAssignment(task) {
    const areAllRequestsAssignedToDrive = task.inquiries.every(
      (request) => Object.hasOwn(request, "drive")
    );
    if (!areAllRequestsAssignedToDrive) {
      throw new ShouldAssignDrive("FT");
    }
  }
  isAlreadyApprovedBy(festivalActivity, team) {
    const teamReview = getTeamReview2(festivalActivity.reviews, team);
    return teamReview === APPROVED;
  }
};
function getTeamReview2(reviews, team) {
  switch (team) {
    case humain:
      return reviews.humain;
    case matos:
      return reviews.matos;
    case elec:
      return reviews.elec;
  }
}
__name(getTeamReview2, "getTeamReview");
export {
  AFFICHE,
  APPROVED,
  ASSIGNMENT_STARTED,
  AskForReview,
  AskForReview2 as AskForReviewTask,
  BACHE,
  BACKLINE,
  BARRIERES,
  BENNE_COLLETTE_BESSON,
  BENNE_PARKING_K_FET,
  CAVE_E,
  CLUB_ROCK,
  COMMENTED,
  CONTENEUR_KARNA,
  CONTENEUR_PARKING_K_FET,
  CONTENEUR_SCENE_ROOTS,
  CONTENUR_24H,
  CREATED,
  CREUX_GCU,
  CREUX_GM,
  CreateFestivalActivity,
  CreateFestivalTask,
  ELEC,
  EnableAssignment,
  FORCED_UPDATE,
  FestivalActivityError,
  FestivalEventError,
  FestivalTaskError,
  FestivalTaskTranslator,
  HALL_DES_HUMANITES,
  InChargeInstructionsSpecification,
  InMemoryAskForReviewFestivalActivityRepository,
  InMemoryCreateFestivalActivityRepository,
  InReviewSpecification,
  LIVRE_PAR_COM,
  LIVRE_PAR_LOGISTIQUE,
  LOCAL_24H,
  MAGASIN,
  MATOS,
  MDE,
  NON_STOCKE,
  NOT_ASKING_TO_REVIEW,
  P17_125A_TETRA,
  P17_16A_MONO,
  P17_16A_TETRA,
  P17_16A_TRI,
  P17_32A_MONO,
  P17_32A_TETRA,
  P17_32A_TRI,
  P17_63A_MONO,
  P17_63A_TETRA,
  P17_63A_TRI,
  PANNEAU,
  PARKING_EIFFEL,
  PC16_Prise_classique,
  PrepareFestivalActivity,
  PrepareFestivalTask,
  QG_ORGA,
  READY_TO_REVIEW,
  REJECTED,
  RESET_REVIEW,
  REVIEWING,
  Review as ReviewTask,
  ReviewableSpecification,
  Reviewing,
  SALLE_CRLA,
  SALLE_MONTREAL,
  SALLE_RENE_CHAR,
  ViewFestivalTask,
  barrieres,
  communication,
  defaultDraft,
  drives,
  elec,
  extractApprovers,
  humain,
  isReviewer as isActivityReviewer,
  isAssignedToDrive,
  isDraft,
  isInReview,
  isLinkedToCatalogItem,
  isReadyToAssign,
  isRefused,
  isRefusedReviews,
  isReviewer2 as isTaskReviewer,
  isValidated,
  isValidatedReviews,
  matos,
  requirableTeams,
  requirableTeamsExtended,
  secu,
  signa,
  signageTypes
};
