var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/slugify.ts
var SlugifyService = class {
  static {
    __name(this, "SlugifyService");
  }
  static convert = /* @__PURE__ */ new Map([
    ["\xE0", "a"],
    ["\xE2", "a"],
    ["\xE4", "a"],
    ["\xE9", "e"],
    ["\xE8", "e"],
    ["\xEA", "e"],
    ["\xEB", "e"],
    ["\xEE", "i"],
    ["\xEF", "i"],
    ["\xF4", "o"],
    ["\xF6", "o"],
    ["\xF2", "o"],
    ["\xFB", "u"],
    ["\xFC", "u"],
    ["\xF9", "u"],
    ["\xE7", "c"],
    ["\u0153", "oe"],
    ["\xE6", "ae"],
    ["\xF8", "o"]
  ]);
  constructor() {
  }
  static apply(sentence) {
    const SLUG_SEPARATOR = "-";
    const newWordDelimiter = new RegExp("[ '/?#]+", "gm");
    const nonStandardChar = new RegExp("[^A-Za-z0-9]", "gm");
    const startOrEndWithSeparator = new RegExp(
      `^[${SLUG_SEPARATOR}]+|[${SLUG_SEPARATOR}]+$`,
      "gm"
    );
    return sentence.toLowerCase().replace(nonStandardChar, (char) => this.convert.get(char) ?? char).replace(newWordDelimiter, SLUG_SEPARATOR).replace(startOrEndWithSeparator, "");
  }
  static applyOnOptional(sentence) {
    return sentence ? this.apply(sentence) : void 0;
  }
};
export {
  SlugifyService
};
