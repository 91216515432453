// src/status.ts
var DRAFT = "DRAFT";
var IN_REVIEW = "IN_REVIEW";
var VALIDATED = "VALIDATED";
var REFUSED = "REFUSED";
var READY_TO_ASSIGN = "READY_TO_ASSIGN";

// src/category.ts
var STATIQUE = "STATIQUE";
var BAR = "BAR";
var MANUTENTION = "MANUTENTION";
var FUN = "FUN";
var RELOU = "RELOU";
var categories = [STATIQUE, BAR, MANUTENTION, FUN, RELOU];
export {
  BAR,
  DRAFT,
  FUN,
  IN_REVIEW,
  MANUTENTION,
  READY_TO_ASSIGN,
  REFUSED,
  RELOU,
  STATIQUE,
  VALIDATED,
  categories
};
