var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/list.ts
function updateItemToList(list, index, newValue) {
  if (index < 0) return updateItemToList(list, list.length + index, newValue);
  return [...list.slice(0, index), newValue, ...list.slice(index + 1)];
}
__name(updateItemToList, "updateItemToList");
function removeItemAtIndex(list, index) {
  if (index < 0) return removeItemAtIndex(list, list.length + index);
  return [...list.slice(0, index), ...list.slice(index + 1)];
}
__name(removeItemAtIndex, "removeItemAtIndex");
function moveAtFirstIndex(list, index) {
  const item = list.at(index);
  if (!item) return list;
  return [item, ...list.slice(0, index), ...list.slice(index + 1)];
}
__name(moveAtFirstIndex, "moveAtFirstIndex");
function hasAtLeastOneItem(list) {
  return list.length > 0;
}
__name(hasAtLeastOneItem, "hasAtLeastOneItem");
function isEmpty(list) {
  return list.length === 0;
}
__name(isEmpty, "isEmpty");
var MISSING_ITEM_INDEX = -1;

// src/generator.ts
function* numberGenerator(start, excludes = []) {
  for (let i = start; i < 1e6; i++) {
    if (excludes.includes(i)) continue;
    yield i;
  }
}
__name(numberGenerator, "numberGenerator");
export {
  MISSING_ITEM_INDEX,
  hasAtLeastOneItem,
  isEmpty,
  moveAtFirstIndex,
  numberGenerator,
  removeItemAtIndex,
  updateItemToList
};
