var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/registration.constant.ts
var readableCommitmentPresentation = `
<p>Hello et merci d'\xEAtre venu jusqu'ici ! \u{1F917}</p>
<p>
Le festival des 24 heures de l'INSA n'est possible que gr\xE2ce aux 
plus de 
<strong>400 b\xE9n\xE9voles</strong> qui, comme toi, ont accept\xE9 de venir 
nous donner un coup de main pour pr\xE9parer, organiser et faire en 
sorte que les festivaliers passent le meilleur moment possible. \u{1F389}
</p>
<p>
Pour que l'on puisse t'accueillir dans les meilleures conditions il 
nous faut cependant quelques informations sur toi et le temps que tu 
souhaites consacrer \xE0 nous aider.
</p>
<p>
Si tu as la moindre question sur le festival et ce que tu peux faire 
pour nous aider, n'h\xE9site pas a contacter les 
<a href="mailto:humains@24heures.org">responsables b\xE9n\xE9voles</a>. 
Ils seront tes principaux interlocuteurs en amont et en aval du 
festival. \u{1F60E}
</p>`;
var defaultCommitmentPresentation = readableCommitmentPresentation.replace(/\n/g, "");

// src/register-form/fulfilled-registration.ts
var BDE_CODE = "bde";
var STRASBOURG_CODE = "strasbourg";
var KFET_CODE = "kfet";
var KARNA_CODE = "karna";
var TECKOS_CODE = "teckos";
var TENDRESTIVAL_CODE = "tendrestival";
var CVL_CODE = "cvl";
var TEAM_CODES = [
  BDE_CODE,
  STRASBOURG_CODE,
  KFET_CODE,
  KARNA_CODE,
  TECKOS_CODE,
  TENDRESTIVAL_CODE,
  CVL_CODE
];

// src/newcomer.ts
function isJoinableTeams(teams) {
  const maxTwoTeams = teams.length <= 2;
  const onlyJoinableTeams = teams.every(
    (team) => TEAM_CODES.some((code) => code === team)
  );
  return maxTwoTeams && onlyJoinableTeams;
}
__name(isJoinableTeams, "isJoinableTeams");
var STAFF = "STAFF";
var VOLUNTEER = "VOLUNTEER";
function isStaffRegistered(registree) {
  return registree.membership === STAFF;
}
__name(isStaffRegistered, "isStaffRegistered");
function isVolunteerRegistered(registree) {
  return registree.membership === VOLUNTEER;
}
__name(isVolunteerRegistered, "isVolunteerRegistered");

// src/register-newcomer.ts
import { ENROLL_HARD, ENROLL_SOFT } from "@overbookd/permission";

// src/register-form/fields/email-field.ts
var EmailField = class _EmailField {
  constructor(email) {
    this.email = email;
  }
  static {
    __name(this, "EmailField");
  }
  emailPattern = new RegExp(
    "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"
  );
  insaEmailPattern = new RegExp("^((?!insa-lyon.fr).)*$");
  validEmail = {
    test: /* @__PURE__ */ __name((value) => this.emailPattern.test(value), "test"),
    reason: "Adresse mail non valable"
  };
  nonInsaEmail = {
    test: /* @__PURE__ */ __name((value) => this.insaEmailPattern.test(value), "test"),
    reason: "Pas d'adresse insa \u{1F64F}"
  };
  static build(email) {
    return new _EmailField(email);
  }
  get value() {
    return this.email.toLowerCase();
  }
  get rules() {
    return [this.validEmail, this.nonInsaEmail];
  }
  get isValid() {
    return this.rules.every((rule) => rule.test(this.email));
  }
  get reasons() {
    return this.rules.filter((rule) => !rule.test(this.email)).map(({ reason }) => reason);
  }
};

// src/register-form/fields/firstname-field.ts
var FirstnameField = class _FirstnameField {
  constructor(firstname) {
    this.firstname = firstname;
  }
  static {
    __name(this, "FirstnameField");
  }
  get value() {
    return this.firstname;
  }
  get isValid() {
    return this.firstname.length > 0;
  }
  get reasons() {
    return this.isValid ? [] : ["Il faut renseigner un prenom"];
  }
  static build(firstname) {
    return new _FirstnameField(firstname);
  }
};

// src/register-form/fields/lastname-field.ts
var LastnameField = class _LastnameField {
  constructor(lastname) {
    this.lastname = lastname;
  }
  static {
    __name(this, "LastnameField");
  }
  get value() {
    return this.lastname;
  }
  get isValid() {
    return this.lastname.length > 0;
  }
  get reasons() {
    return this.isValid ? [] : ["Il faut renseigner un nom"];
  }
  static build(lastname) {
    return new _LastnameField(lastname);
  }
};

// src/register-form/fields/password-field.ts
var SPECIAL_CHARS_REGEX_PATERN = "[!@#$%^&*+=_.,;:?{}()/|\\-]";
var PasswordField = class _PasswordField {
  constructor(password) {
    this.password = password;
  }
  static {
    __name(this, "PasswordField");
  }
  minusculePattern = new RegExp("[a-z]");
  majusculePattern = new RegExp("[A-Z]");
  numberPattern = new RegExp("[0-9]");
  specialCharPattern = new RegExp(SPECIAL_CHARS_REGEX_PATERN);
  minPasswordLength = 12;
  containsMinuscule = {
    test: /* @__PURE__ */ __name((value) => this.minusculePattern.test(value), "test"),
    reason: "Il faut au moins une minuscule dans le mot de passe"
  };
  containsMajuscule = {
    test: /* @__PURE__ */ __name((value) => this.majusculePattern.test(value), "test"),
    reason: "Il faut au moins une MAJUSCULE dans le mot de passe"
  };
  containsNumber = {
    test: /* @__PURE__ */ __name((value) => this.numberPattern.test(value), "test"),
    reason: "Il faut au moins un chiffre dans le mot de passe"
  };
  containsSpecialChar = {
    test: /* @__PURE__ */ __name((value) => this.specialCharPattern.test(value), "test"),
    reason: "Il faut au moins un caract\xE8re sp\xE9cial (!@#$%^&*=+_{}[]()|.) dans le mot de passe"
  };
  atLeast8CharactersLong = {
    test: /* @__PURE__ */ __name((value) => value.length >= this.minPasswordLength, "test"),
    reason: `Il faut au moins ${this.minPasswordLength} caract\xE8res dans le mot de passe`
  };
  static build(password) {
    return new _PasswordField(password);
  }
  get value() {
    return this.password;
  }
  get rules() {
    return [
      this.containsMinuscule,
      this.containsMajuscule,
      this.containsNumber,
      this.containsSpecialChar,
      this.atLeast8CharactersLong
    ];
  }
  get isValid() {
    return this.rules.every((rule) => rule.test(this.password));
  }
  get reasons() {
    return this.rules.filter((rule) => !rule.test(this.password)).map(({ reason }) => reason);
  }
};

// src/register-form/fields/mobile-phone-field.ts
var MobilePhoneField = class _MobilePhoneField {
  constructor(mobilePhone) {
    this.mobilePhone = mobilePhone;
  }
  static {
    __name(this, "MobilePhoneField");
  }
  mobilePhoneNumberPattern = new RegExp(
    "^(0|[+]([1-9])([0-9])?([0-9])?)[6-7]{1}[0-9]{8}$"
  );
  internationalFormatPattern = new RegExp(
    "^[+][1-9][0-9]?[0-9]?"
  );
  validMobilePhone = {
    test: /* @__PURE__ */ __name((value) => this.mobilePhoneNumberPattern.test(value), "test"),
    reason: "Num\xE9ro de t\xE9l\xE9phone mobile non valable"
  };
  nonInternationalFormat = {
    test: /* @__PURE__ */ __name((value) => !this.internationalFormatPattern.test(value), "test"),
    reason: "Nous ne supportons pas les num\xE9ros au format international"
  };
  get value() {
    return this.mobilePhone;
  }
  get rules() {
    return [this.validMobilePhone, this.nonInternationalFormat];
  }
  get isValid() {
    return this.rules.every((rule) => rule.test(this.mobilePhone));
  }
  get reasons() {
    return this.rules.filter((rule) => !rule.test(this.mobilePhone)).map(({ reason }) => reason);
  }
  static build(mobilePhone) {
    return new _MobilePhoneField(mobilePhone);
  }
};

// src/register-form/fields/nickname-field.ts
var NicknameField = class _NicknameField {
  constructor(nickname) {
    this.nickname = nickname;
  }
  static {
    __name(this, "NicknameField");
  }
  get value() {
    return this.nickname;
  }
  get isValid() {
    return this.nickname === void 0 ? true : this.nickname.length > 0;
  }
  get reasons() {
    return this.isValid ? [] : ["Il faut renseigner un surnom"];
  }
  static build(nickname) {
    return new _NicknameField(nickname);
  }
};

// src/register-form/fields/birthdate-field.ts
var BirthdateField = class _BirthdateField {
  constructor(birthdate) {
    this.birthdate = birthdate;
  }
  static {
    __name(this, "BirthdateField");
  }
  minimumBirthdate = /* @__PURE__ */ new Date("1950-01-01");
  notBefore1950 = {
    test: /* @__PURE__ */ __name((value) => value.getTime() > this.minimumBirthdate.getTime(), "test"),
    reason: "Tu n'es pas si vieux !"
  };
  notInFuture = {
    test: /* @__PURE__ */ __name((value) => value.getTime() < Date.now(), "test"),
    reason: "Tu ne peux pas na\xEEtre dans le futur \u{1F575}\uFE0F\u200D\u2642\uFE0F"
  };
  static build(birthdate) {
    return new _BirthdateField(birthdate);
  }
  get value() {
    return this.birthdate;
  }
  get rules() {
    return [this.notBefore1950, this.notInFuture];
  }
  get isValid() {
    return this.rules.every((rule) => rule.test(this.birthdate));
  }
  get reasons() {
    return this.rules.filter((rule) => !rule.test(this.birthdate)).map(({ reason }) => reason);
  }
};

// src/register-form/fields/comment-field.ts
var CommentField = class _CommentField {
  constructor(comment) {
    this.comment = comment;
  }
  static {
    __name(this, "CommentField");
  }
  get value() {
    return this.comment;
  }
  get isValid() {
    return this.comment === void 0 ? true : this.comment.length > 0;
  }
  get reasons() {
    return this.isValid ? [] : ["Il faut pr\xE9ciser ton commentaire"];
  }
  static build(comment) {
    return new _CommentField(comment);
  }
};

// src/register-form/fields/teams-field.ts
var TeamsField = class _TeamsField {
  constructor(teams) {
    this.teams = teams;
  }
  static {
    __name(this, "TeamsField");
  }
  get value() {
    return this.teams;
  }
  MAX_TEAM_ALLOWED = 2;
  get isValid() {
    return this.teams.length <= this.MAX_TEAM_ALLOWED;
  }
  get reasons() {
    return this.isValid ? [] : [`Tu ne peux pas rejoindre plus de ${this.MAX_TEAM_ALLOWED} \xE9quipes`];
  }
  static build(teams) {
    return new _TeamsField(teams);
  }
};

// src/register-form/fields/EULA-field.ts
var EULAField = class _EULAField {
  constructor(hasApprovedEULA) {
    this.hasApprovedEULA = hasApprovedEULA;
  }
  static {
    __name(this, "EULAField");
  }
  get value() {
    return this.hasApprovedEULA;
  }
  get isValid() {
    return this.hasApprovedEULA === true;
  }
  get reasons() {
    return this.isValid ? [] : ["Les Condidtions G\xE9n\xE9rales d'Utilisation doivent \xEAtre approuv\xE9es"];
  }
  static build(hasApprovedEULA) {
    return new _EULAField(hasApprovedEULA ?? false);
  }
};

// src/register-form/register-form.ts
var RegisterForm = class _RegisterForm {
  static {
    __name(this, "RegisterForm");
  }
  email;
  firstname;
  lastname;
  password;
  mobilePhone;
  nickname;
  birthdate;
  comment;
  teams;
  EULA;
  constructor({
    email,
    firstname,
    lastname,
    password,
    mobilePhone,
    nickname,
    birthdate,
    comment,
    teams,
    hasApprovedEULA
  }) {
    this.email = EmailField.build(email ?? "");
    this.firstname = FirstnameField.build(firstname ?? "");
    this.lastname = LastnameField.build(lastname ?? "");
    this.password = PasswordField.build(password ?? "");
    this.mobilePhone = MobilePhoneField.build(mobilePhone ?? "");
    this.nickname = NicknameField.build(nickname);
    this.birthdate = BirthdateField.build(birthdate ?? /* @__PURE__ */ new Date("1949-12-25"));
    this.comment = CommentField.build(comment);
    this.teams = TeamsField.build(teams ?? []);
    this.EULA = EULAField.build(hasApprovedEULA);
  }
  static init() {
    return new _RegisterForm({});
  }
  fillEmail(email) {
    return new _RegisterForm({ ...this.currentRegistration, email });
  }
  clearEmail() {
    return new _RegisterForm({ ...this.currentRegistration, email: void 0 });
  }
  fillFirstname(firstname) {
    return new _RegisterForm({ ...this.currentRegistration, firstname });
  }
  clearFirstname() {
    return new _RegisterForm({
      ...this.currentRegistration,
      firstname: void 0
    });
  }
  fillLastname(lastname) {
    return new _RegisterForm({ ...this.currentRegistration, lastname });
  }
  clearLastname() {
    return new _RegisterForm({
      ...this.currentRegistration,
      lastname: void 0
    });
  }
  fillPassword(password) {
    return new _RegisterForm({ ...this.currentRegistration, password });
  }
  clearPassword() {
    return new _RegisterForm({
      ...this.currentRegistration,
      password: void 0
    });
  }
  fillMobilePhone(mobilePhone) {
    return new _RegisterForm({ ...this.currentRegistration, mobilePhone });
  }
  clearMobilePhone() {
    return new _RegisterForm({
      ...this.currentRegistration,
      mobilePhone: void 0
    });
  }
  fillNickname(nickname) {
    return new _RegisterForm({ ...this.currentRegistration, nickname });
  }
  clearNickname() {
    return new _RegisterForm({
      ...this.currentRegistration,
      nickname: void 0
    });
  }
  fillBirthdate(birthdate) {
    return new _RegisterForm({ ...this.currentRegistration, birthdate });
  }
  clearBirthdate() {
    return new _RegisterForm({
      ...this.currentRegistration,
      birthdate: void 0
    });
  }
  fillComment(comment) {
    return new _RegisterForm({ ...this.currentRegistration, comment });
  }
  clearComment() {
    return new _RegisterForm({
      ...this.currentRegistration,
      comment: void 0
    });
  }
  fillTeams(teams) {
    return new _RegisterForm({ ...this.currentRegistration, teams });
  }
  clearTeams() {
    return new _RegisterForm({ ...this.currentRegistration, teams: [] });
  }
  approveEndUserLicenceAgreement() {
    return new _RegisterForm({
      ...this.currentRegistration,
      hasApprovedEULA: true
    });
  }
  denyEndUserLicenceAgreement() {
    return new _RegisterForm({
      ...this.currentRegistration,
      hasApprovedEULA: false
    });
  }
  get currentRegistration() {
    return {
      email: this.email.value,
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      password: this.password.value,
      mobilePhone: this.mobilePhone.value,
      nickname: this.nickname.value,
      birthdate: this.birthdate.value,
      comment: this.comment.value,
      teams: this.teams.value,
      hasApprovedEULA: this.EULA.value
    };
  }
  get fields() {
    return [
      this.email,
      this.firstname,
      this.lastname,
      this.password,
      this.mobilePhone,
      this.nickname,
      this.birthdate,
      this.comment,
      this.teams,
      this.EULA
    ];
  }
  get isValid() {
    return this.fields.every((field) => field?.isValid);
  }
  get reasons() {
    return this.fields.flatMap((field) => field.reasons);
  }
  complete() {
    if (!this.isFulfilled(this.currentRegistration)) {
      throw new NotFulfilledRegistration(this.reasons);
    }
    return this.currentRegistration;
  }
  isFulfilled(registration) {
    return this.isValid;
  }
};
var RegistrationError = class extends Error {
  constructor(reasons, errorMessage = "Erreur lors de l'inscription") {
    super(`${errorMessage}:
${reasons.join("\n")}`);
    this.reasons = reasons;
  }
  static {
    __name(this, "RegistrationError");
  }
};
var NotFulfilledRegistration = class extends RegistrationError {
  static {
    __name(this, "NotFulfilledRegistration");
  }
  constructor(reasons) {
    super(reasons, "L'inscription n'est pas compl\xE8te");
  }
};

// src/register-newcomer.ts
var RegisterNewcomer = class {
  constructor(newcomerRepository, notificationRepository) {
    this.newcomerRepository = newcomerRepository;
    this.notificationRepository = notificationRepository;
  }
  static {
    __name(this, "RegisterNewcomer");
  }
  commentAction(form, comment) {
    if (comment === void 0) return form.clearComment();
    return form.fillComment(comment);
  }
  nicknameAction(form, nickname) {
    if (nickname === void 0) return form.clearNickname();
    return form.fillNickname(nickname);
  }
  async fromRegisterForm(form, membership) {
    const dataForm = this.commentAction(
      this.nicknameAction(RegisterForm.init(), form.nickname),
      form.comment
    ).fillEmail(form.email ?? "").fillFirstname(form.firstname ?? "").fillLastname(form.lastname ?? "").fillPassword(form.password ?? "").fillMobilePhone(form.mobilePhone ?? "").fillBirthdate(form.birthdate ?? /* @__PURE__ */ new Date("1949-12-25")).fillTeams(form.teams ?? []);
    const dataFormWithEULA = form.hasApprovedEULA ? dataForm.approveEndUserLicenceAgreement() : dataForm.denyEndUserLicenceAgreement();
    const fulfilledForm = dataFormWithEULA.complete();
    const isEmailAlreadyUsed = await this.newcomerRepository.isEmailUsed(
      fulfilledForm.email
    );
    if (isEmailAlreadyUsed) {
      throw new RegistrationError([
        "L'email est d\xE9ja utilis\xE9 par un autre utilisateur"
      ]);
    }
    return this.newcomerRepository.save(fulfilledForm, membership);
  }
  notifyNewStaffAwaits(newcomer) {
    return this.notificationRepository.add(newcomer, {
      permission: ENROLL_HARD
    });
  }
  notifyNewVolunteerAwaits(newcomer) {
    return this.notificationRepository.add(newcomer, {
      permission: ENROLL_SOFT
    });
  }
};

// src/enroll/joinable-team.ts
import { HARD_CODE, SOFT_CODE } from "@overbookd/team-constants";
var joinableTeams = {
  SOFT_CODE,
  HARD_CODE
};

// src/enroll/enroll-candidates.ts
import { BENEVOLE_CODE } from "@overbookd/team-constants";
var EnrollCandidates = class _EnrollCandidates {
  constructor(candidates) {
    this.candidates = candidates;
  }
  static {
    __name(this, "EnrollCandidates");
  }
  static with(candidates) {
    return new _EnrollCandidates(candidates);
  }
  to(team) {
    return this.candidates.map((candidate) => ({
      ...candidate,
      teams: [team, BENEVOLE_CODE]
    }));
  }
};

// src/forget-member/forget-member.error.ts
var I_M_ASSIGNED_IN_FUTUR_TASK_ERROR_MESSAGE = "Nous ne pouvons pas t'effacer d'Overbookd.\nTu es affect\xE9 \xE0 une t\xE2che \xE0 venir.\nContacte les responsables b\xE9n\xE9voles pour t'aider.";
var ASSIGNED_IN_FUTUR_TASK_ERROR_MESSAGE = "Nous ne pouvons pas l'effacer d'Overbookd.\nIel est affect\xE9(e) \xE0 une t\xE2che \xE0 venir.";
var I_M_IN_DEBT_ERROR_MESSAGE = "Nous ne pouvons pas t'effacer d'Overbookd.\nTu as des dettes aupr\xE8s de l'association.\nContacte le secr\xE9taire g\xE9n\xE9ral pour t'aider.";
var IN_DEBT_ERROR_MESSAGE = "Nous ne pouvons pas l'effacer d'Overbookd.\nIel a des dettes aupr\xE8s de l'association.";
var WRONG_CREDENTIALS_ERROR_MESSAGE = "Nous ne pouvons pas t'effacer d'Overbookd.\nLe mot de passe et l'adresse mail ne correspondent pas";
var ALREADY_HAVE_TRANSACTIONS = "Nous ne pouvons pas l'effacer d'Overbookd.\nIel a d\xE9j\xE0 effectu\xE9 des transactions.";
var ForgetMemberError = class extends Error {
  static {
    __name(this, "ForgetMemberError");
  }
};
var AssignedInFuturTask = class extends ForgetMemberError {
  static {
    __name(this, "AssignedInFuturTask");
  }
  constructor(isMyself = true) {
    const message = isMyself ? I_M_ASSIGNED_IN_FUTUR_TASK_ERROR_MESSAGE : ASSIGNED_IN_FUTUR_TASK_ERROR_MESSAGE;
    super(message);
  }
};
var InDebt = class extends ForgetMemberError {
  static {
    __name(this, "InDebt");
  }
  constructor(isMyself = true) {
    const message = isMyself ? I_M_IN_DEBT_ERROR_MESSAGE : IN_DEBT_ERROR_MESSAGE;
    super(message);
  }
};
var WrongCrendentials = class extends ForgetMemberError {
  static {
    __name(this, "WrongCrendentials");
  }
  constructor() {
    super(WRONG_CREDENTIALS_ERROR_MESSAGE);
  }
};
var HaveTransactions = class extends ForgetMemberError {
  static {
    __name(this, "HaveTransactions");
  }
  constructor() {
    super(ALREADY_HAVE_TRANSACTIONS);
  }
};

// src/forget-member/anonymous-member.ts
var ANONYMOUS = "Anonymous";
var ANONYMOUS_MOBILE_PHONE = "0600000000";

// src/forget-member/forget-about.strategy.ts
var WithoutTransactions = class _WithoutTransactions {
  constructor(id, members) {
    this.id = id;
    this.members = members;
  }
  static {
    __name(this, "WithoutTransactions");
  }
  static init({ id, repository }) {
    return new _WithoutTransactions(id, repository);
  }
  async forget() {
    await this.members.delete(this.id);
  }
};
var WithTransactions = class _WithTransactions {
  constructor(id, members) {
    this.id = id;
    this.members = members;
  }
  static {
    __name(this, "WithTransactions");
  }
  static init({ id, repository }) {
    return new _WithTransactions(id, repository);
  }
  forget() {
    const anonymizedMember = {
      firstname: ANONYMOUS,
      lastname: ANONYMOUS,
      mobilePhone: ANONYMOUS_MOBILE_PHONE,
      email: `anonymous+${this.id}@24heures.org`,
      comment: null,
      nickname: null
    };
    return this.members.anonymize(this.id, anonymizedMember);
  }
};

// src/forget-member/forget-member.ts
var ForgetMember = class {
  constructor(members) {
    this.members = members;
  }
  static {
    __name(this, "ForgetMember");
  }
  async me({ email, password }) {
    const [member, hasTasks, hasDebts, hasTransactions] = await Promise.all([
      this.members.authenticate({ email, password }),
      this.members.hasTasks(email),
      this.members.hasDebts(email),
      this.members.hasTransactions(email)
    ]);
    if (!member) throw new WrongCrendentials();
    if (hasTasks) throw new AssignedInFuturTask();
    if (hasDebts) throw new InDebt();
    const strategy = hasTransactions ? WithTransactions : WithoutTransactions;
    const strategyInitializer = { id: member.id, repository: this.members };
    return strategy.init(strategyInitializer).forget();
  }
  async him(email) {
    const [hasTasks, hasDebts, hasTransactions, id] = await Promise.all([
      this.members.hasTasks(email),
      this.members.hasDebts(email),
      this.members.hasTransactions(email),
      this.members.getId(email)
    ]);
    if (!id) return;
    if (hasTasks) throw new AssignedInFuturTask(false);
    if (hasDebts) throw new InDebt(false);
    if (hasTransactions) throw new HaveTransactions();
    return WithoutTransactions.init({ id, repository: this.members }).forget();
  }
};

// src/invite-staff/invite-staff.ts
import { jwtDecode } from "jwt-decode";
import { SignJWT, jwtVerify } from "jose";
import {
  formatDateWithExplicitMonthAndDay,
  ONE_SECOND_IN_MS
} from "@overbookd/time";
import { LOGIN_URL } from "@overbookd/web-page";
var TOKEN = "token";
var LINK_EXPIRED = "Le lien a expir\xE9";
var InviteStaff = class _InviteStaff {
  static {
    __name(this, "InviteStaff");
  }
  static async byLink({ domain, secret }) {
    const baseUrl = new URL(`https://${domain}${LOGIN_URL}`);
    const secretKey = new TextEncoder().encode(secret);
    const token = await new SignJWT().setExpirationTime("30 days").setProtectedHeader({ alg: "HS256" }).sign(secretKey);
    baseUrl.searchParams.append(TOKEN, token);
    return baseUrl;
  }
  static isLinkExpired(link) {
    const token = link.searchParams.get(TOKEN);
    try {
      const { exp } = jwtDecode(token ?? "");
      const expirationInMs = exp * ONE_SECOND_IN_MS;
      if (_InviteStaff.isPast(expirationInMs)) return LINK_EXPIRED;
      const expirationDate = formatDateWithExplicitMonthAndDay(expirationInMs);
      return `Le lien expire le ${expirationDate}`;
    } catch {
      return LINK_EXPIRED;
    }
  }
  static async isInvitationValid({ token, secret }) {
    try {
      const secretKey = new TextEncoder().encode(secret);
      await jwtVerify(token, secretKey);
      return true;
    } catch {
      return false;
    }
  }
  static isPast(expirationInMs) {
    return expirationInMs < Date.now();
  }
};

// src/membership-application/apply-for.ts
import { Edition } from "@overbookd/time";

// src/membership-application/candidature.error.ts
function readableMembership(membership) {
  return membership === STAFF ? "orga" : "b\xE9n\xE9vole";
}
__name(readableMembership, "readableMembership");
var MembershipApplicationError = class extends Error {
  static {
    __name(this, "MembershipApplicationError");
  }
};
var AlreadyCandidate = class extends MembershipApplicationError {
  constructor(membership) {
    super(
      `Tu as d\xE9j\xE0 candidat\xE9 pour \xEAtre ${readableMembership(membership)} pour cette \xE9dition`
    );
    this.membership = membership;
  }
  static {
    __name(this, "AlreadyCandidate");
  }
};
var Rejected = class extends MembershipApplicationError {
  constructor(membership) {
    super(
      `Ta candidature pour \xEAtre ${readableMembership(membership)} a \xE9t\xE9 rejet\xE9e pour cette \xE9dition`
    );
    this.membership = membership;
  }
  static {
    __name(this, "Rejected");
  }
};
var AlreadyRejected = class extends MembershipApplicationError {
  constructor(membership) {
    super(
      `Le b\xE9n\xE9vole a d\xE9j\xE0 \xE9t\xE9 rejet\xE9 pour \xEAtre ${readableMembership(membership)} pour cette \xE9dition`
    );
    this.membership = membership;
  }
  static {
    __name(this, "AlreadyRejected");
  }
};
var NotRejected = class extends MembershipApplicationError {
  constructor(membership) {
    super(
      `Le b\xE9n\xE9vole n'a pas \xE9t\xE9 rejet\xE9 pour \xEAtre ${readableMembership(membership)} pour cette \xE9dition`
    );
    this.membership = membership;
  }
  static {
    __name(this, "NotRejected");
  }
};

// src/membership-application/apply-for.ts
var ApplyFor = class {
  constructor(candidates) {
    this.candidates = candidates;
  }
  static {
    __name(this, "ApplyFor");
  }
  async staff({ email }) {
    const edition = Edition.current;
    const hasRejectedApplication = await this.candidates.isRejected(
      email,
      edition,
      STAFF
    );
    if (hasRejectedApplication) throw new Rejected(STAFF);
    const isCandidate = await this.candidates.isCandidate(
      email,
      edition,
      STAFF
    );
    if (isCandidate) throw new AlreadyCandidate(STAFF);
    const newCandidate = {
      email,
      membership: STAFF,
      edition,
      isRejected: false,
      candidatedAt: /* @__PURE__ */ new Date()
    };
    return this.candidates.add(newCandidate);
  }
  async volunteer({ email }) {
    const edition = Edition.current;
    const hasRejectedApplication = await this.candidates.isRejected(
      email,
      edition,
      VOLUNTEER
    );
    if (hasRejectedApplication) throw new Rejected(VOLUNTEER);
    const isCandidate = await this.candidates.isCandidate(
      email,
      edition,
      VOLUNTEER
    );
    if (isCandidate) throw new AlreadyCandidate(VOLUNTEER);
    const newCandidate = {
      email,
      membership: VOLUNTEER,
      edition,
      isRejected: false,
      candidatedAt: /* @__PURE__ */ new Date()
    };
    return this.candidates.add(newCandidate);
  }
};

// src/membership-application/reject.ts
import { Edition as Edition2 } from "@overbookd/time";
var RejectMembershipApplication = class {
  constructor(candidates) {
    this.candidates = candidates;
  }
  static {
    __name(this, "RejectMembershipApplication");
  }
  async applyOne({ email }, membership) {
    const edition = Edition2.current;
    const isRejected = await this.candidates.isRejected(
      email,
      edition,
      membership
    );
    if (isRejected) throw new AlreadyRejected(membership);
    return this.candidates.reject(email, edition, membership);
  }
  async unapplyOne({ email }, membership) {
    const edition = Edition2.current;
    const isRejected = await this.candidates.isRejected(
      email,
      edition,
      membership
    );
    if (!isRejected) throw new NotRejected(membership);
    return this.candidates.cancelRejection(email, edition, membership);
  }
};
export {
  ANONYMOUS,
  ANONYMOUS_MOBILE_PHONE,
  AlreadyCandidate,
  ApplyFor,
  BDE_CODE,
  CVL_CODE,
  EnrollCandidates,
  ForgetMember,
  ForgetMemberError,
  InviteStaff,
  KARNA_CODE,
  KFET_CODE,
  LINK_EXPIRED,
  MembershipApplicationError,
  RegisterForm,
  RegisterNewcomer,
  RegistrationError,
  RejectMembershipApplication,
  SPECIAL_CHARS_REGEX_PATERN,
  STAFF,
  STRASBOURG_CODE,
  TEAM_CODES,
  TECKOS_CODE,
  TENDRESTIVAL_CODE,
  VOLUNTEER,
  defaultCommitmentPresentation,
  isJoinableTeams,
  isStaffRegistered,
  isVolunteerRegistered,
  joinableTeams
};
